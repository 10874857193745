$block: '.CircularProgressbar';

.body {
  #{$block} {
    #{$block}-path {
      stroke: var(--color-success-60);
    }
    #{$block}-text {
      font-size: rem(22px);
      letter-spacing: 0.02em;
      font-weight: 600;
      fill: var(--color-neutral-40);
    }
  }
}
