$block: '.message';

#{$block} {
  position: relative;
  top: rem(-4px);
  z-index: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: rem(8px);
  border-width: $form-border-width;
  border-bottom-right-radius: $form-border-radius;
  border-bottom-left-radius: $form-border-radius;
  border-color: $form-color-border;
  background-color: $form-color-bg--message;
  padding-left: $form-padding-left;
  padding-right: rem(40px);
  padding-top: $form-padding-top--mesage;
  padding-bottom: $form-padding-bottom--mesage;
  color: $form-color-font;
  font-weight: 500;
  box-shadow: $form-bs--message;

  &__text {
    text-align: center;
    font-size: inherit;
    font-weight: inherit;
    line-height: inherit;
    color: inherit;
  }

  &__btn {
    position: absolute;
    bottom: rem(4px);
    right: rem(12px);
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    height: rem(24px);
    width: rem(24px);
    font-size: inherit;
    line-height: inherit;
    font-weight: inherit;
    color: inherit;
    cursor: pointer;
    transition: all 0.2s ease;

    @include breakpoint-min(md) {
      &:hover {
        filter: contrast(0.5);
      }
    }
  }

  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    height: rem(20px);
    width: rem(20px);
    color: inherit;
  }

  &--success {
    color: $form-color-font--success;
    border-color: $form-color-border--success;
    background-color: $form-color-bg--message--success;
    box-shadow: $form-bs--message--success;
  }

  &--error {
    color: $form-color-font--error;
    border-color: $form-color-border--error;
    background-color: $form-color-bg--message--error;
    box-shadow: $form-bs--message--error;
  }
}
