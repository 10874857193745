$block: '.form';
$select: '.select';

#{$block} {
  display: flex;
  flex-direction: column;
  gap: $form-gap;
  width: 100%;

  &__grid {
    display: grid;
    gap: $form-gap-grid;
    
    @include breakpoint-max(xxl) {
      gap: $form-gap-grid--xxl;
    }
  }

  &__group {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: $form-gap-group;
    
    @include breakpoint-max(xxl) {
      gap: $form-gap-group--xxl;
    }
  }

  &__row {
    display: flex;
    align-items: center;
    gap: $form-gap-row;
    width: 100%;
    
    @include breakpoint-max(xxl) {
      gap: $form-gap-row--xxl;
    }

    &--between {
      justify-content: space-between;
    }
  }

  &__btn-icon {
    position: absolute;
    right: 0;
    top: $form-top-position-icon;
    display: flex;
    align-items: center;
    justify-content: center;
    height: $form-height;
    width: $form-height;
    color: $form-color-font;
    border-width: $form-border-width;
    border-style: solid;
    border-color: transparent;
    border-radius: $form-border-radius;
    background-color: transparent;
    cursor: pointer;
    transition: background-color 0.2s, border-color 0.2s, color 0.2s;

    @include breakpoint-min(md) {
      &:hover {
        #{$block} {
          &__icon {
            color: var(--color-primary-40);
          }
        }
      }
    }

    &--large {
      height: $form-height--large;
      width: $form-height--large;
    }

    &--small {
      height: $form-height--small;
      width: $form-height--small;
    }
  }

  &__btn-message {
    position: relative;
    display: flex;
    flex-direction: column;

    > .btn {
      z-index: 2;
    }
  }

  &__icon {
    width: $form-icon-size;
    height: $form-icon-size;
    color: $form-color-font;
    border-width: $form-border-width;
    border-style: solid;
    border-color: transparent;
    border-radius: $form-border-radius;
    background-color: transparent;
    transition: background-color 0.2s, border-color 0.2s, color 0.2s;

    &--large {
      height: $form-icon-size--large;
      width: $form-icon-size--large;
    }

    &--small {
      height: $form-icon-size--small;
      width: $form-icon-size--small;
    }
  }

  &__link {
    font-size: rem(14px);
    line-height: rem(20px);
  }

  &__label {
    font-size: $form-label-font-size;
    line-height: $form-label-line-height;
    font-weight: 500;

    &--large {
      font-size: $form-label-font-size--large;
      line-height: $form-label-line-height--large;
    }

    &--small {
      font-size: $form-label-font-size--small;
      line-height: $form-label-line-height--small;
    }
  }

  &__field {
    position: relative;
    width: 100%;
    min-height: $form-height;
    padding-top: $form-padding-top;
    padding-bottom: $form-padding-bottom;
    padding-left: $form-padding-left;
    padding-right: $form-padding-right;
    font-family: $form-font-family;
    font-size: $form-font-size;
    line-height: $form-line-height;
    letter-spacing: $form-letter-spacing;
    font-weight: $form-font-weight;
    color: $form-color-font;
    border-style: solid;
    border-width: $form-border-width;
    border-radius: $form-border-radius;
    border-color: $form-color-border;
    outline-style: solid;
    outline-offset: $form-ring-offset;
    outline-width: $form-ring-width;
    outline-color: transparent;
    background-color: $form-color-bg;
    transition: $form-transition;

    &::placeholder {
      font-size: $form-placeholder-font-size;
      color: $form-placeholder-color-font;
    }

    &--large {
      min-height: $form-height--large;
      padding-top: $form-padding-top--large;
      padding-bottom: $form-padding-bottom--large;
      padding-right: $form-padding-right--large;
      padding-left: $form-padding-left--large;
      font-size: $form-font-size--large;
      line-height: $form-line-height--large;
      letter-spacing: $form-letter-spacing--large;
      font-weight: $form-font-weight--large;
      text-transform: $form-text-transform--large;
      border-width: $form-border-width--large;
      border-radius: $form-border-radius--large;
      outline-width: $form-ring-width--large;
      outline-offset: $form-ring-offset--large;

      &::placeholder {
        font-size: $form-placeholder-font-size--large;
      }
    }

    &--small {
      min-height: $form-height--small;
      padding-top: $form-padding-top--small;
      padding-bottom: $form-padding-bottom--small;
      padding-right: $form-padding-right--small;
      padding-left: $form-padding-left--small;
      font-size: $form-font-size--small;
      line-height: $form-line-height--small;
      letter-spacing: $form-letter-spacing--small;
      font-weight: $form-font-weight--small;
      text-transform: $form-text-transform--small;
      border-width: $form-border-width--small;
      border-radius: $form-border-radius--small;
      outline-width: $form-ring-width--small;
      outline-offset: $form-ring-offset--small;

      &::placeholder {
        font-size: $form-placeholder-font-size--small;
      }
    }

    &--focus,
    &:focus {
      color: $form-color-font--success;
      border-color: $form-color-border--success;
      outline-color: $form-color-ring--success;
      background-color: $form-color-bg--success;
    }

    &--active,
    &:active {
      color: $form-color-font__active;
      border-color: $form-color-border__active;
      outline-color: $form-color-ring__active;
      background-color: $form-color-bg__active;
    }

    &--valid,
    &--success {
      color: $form-color-font--success;
      border-color: $form-color-border--success;
      outline-color: $form-color-ring--success;
      background-color: $form-color-bg--success;
    }

    &--invalid,
    &--error {
      color: $form-color-font--error;
      border-color: $form-color-border--error;
      outline-color: $form-color-ring--error;
      background-color: $form-color-bg--error;
    }

    &--disabled,
    &:disabled,
    &:read-only:not(#{$select}__field):not(#{$select}__option-btn) {
      color: $form-color-font__disabled !important;
      border-color: $form-color-border__disabled !important;
      outline-color: $form-color-ring__disabled !important;
      background-color: $form-color-bg__disabled !important;
      pointer-events: none;

      &::placeholder {
        color: $form-placeholder-color-font__disabled;
      }
    }
  }

  &__error-message {
    font-size: rem(12px);
    color: var(--color-error-30);

    &--large {
      font-size: $form-font-size;
    }
  }
}

#{$select} {
  $option-icon-size: $form-icon-size;
  $option-icon-left-position: rem(16px);
  $option-btn-padding-vertical: rem(10px);
  $option-btn-padding-horizontal: rem(16px);
  $option-btn-padding-left: calc(
    2 * $option-icon-left-position + $option-btn-padding-horizontal
  );

  position: relative;
  display: block;
  width: 100%;

  &__field {
    @extend .form__field;
    position: relative;
    padding-right: $form-padding-icon;
    text-align: left;

    > #{$select}__text {
      text-align: left;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    &--invalid,
    &--error {
      color: $form-color-font--error;
      border-color: $form-color-border--error;
      outline-color: $form-color-ring--error;
      background-color: $form-color-bg--error;
    }
  }

  &__text {
    font-size: inherit;
    line-height: inherit;
    font-weight: inherit;
    color: var(--color-neutral-40);

    &--selected {
      color: var(--color-primary-40) !important;
      font-weight: 600 !important;
    }
  }

  &__arrow {
    position: absolute;
    right: 0;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: calc(32px);
    padding: 0;
    height: 100%;
  }

  &__arrow-icon {
    width: $btn-icon-size;
    height: $btn-icon-size;
    color: var(--color-neutral-50);
  }

  &__option-dropdown {
    overflow: auto;
    position: absolute;
    z-index: $zindex-select-options;
    top: calc(100% + rem(4px));
    display: flex;
    flex-direction: column;
    width: 100%;
    max-height: rem(240px);
    padding-top: $option-btn-padding-vertical;
    padding-bottom: $option-btn-padding-vertical;
    padding-left: $option-btn-padding-vertical;
    padding-right: $option-btn-padding-vertical;
    font-size: inherit;
    line-height: inherit;
    color: inherit;
    border-radius: $br;
    background-color: var(--color-neutral-100);
    box-shadow: 5px 0 20px rgba(0, 0, 0, 0.1);
    outline: unset !important;

    &:focus-visible {
      --tw-border-opacity: 1;
      border-color: hsl(0 0% 80% / var(--tw-border-opacity));
      --tw-text-opacity: 1;
      color: hsl(0 0% 21% / var(--tw-text-opacity));
    }
  }

  &__option-btn {
    @extend .form__field;
    display: flex;
    flex-shrink: 0;
    width: 100%;
    padding-top: $option-btn-padding-vertical;
    padding-bottom: $option-btn-padding-vertical;
    padding-left: $option-btn-padding-left;
    padding-right: $option-btn-padding-horizontal;
    border: unset;
    border-radius: $br;
    cursor: pointer;
    transition: background-color 0.2s;

    #{$select}__text {
      display: flex;
      flex-direction: column;
      gap: rem(4px);
    }

    &--active {
      background-color: var(--color-primary-90);

      #{$select}__text {
        color: var(--color-neutral-20);
      }
    }
  }

  &__comment {
    font-size: rem(12px);
    line-height: 1;
    letter-spacing: 0.04em;
    color: inherit;
  }

  &__option-icon {
    position: absolute;
    left: $option-icon-left-position;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    align-items: center;
    justify-content: center;
    width: $option-icon-size;
    height: $option-icon-size;
    color: inherit;
  }
}
