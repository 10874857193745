$block: '.selection-progress';

#{$block} {
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: $page-container-gap;
  width: 100%;
  padding: rem(24px);
  background-color: var(--color-neutral-100);
  cursor: pointer;
  transition: background-color 0.3s;

  @include breakpoint-max(xl) {
    gap: rem(16px);
    padding: rem(16px);
  }

  @include breakpoint-min(md) {
    &:hover {
      background-color: var(--color-neutral-90);
    }
  }

  @include breakpoint-max(sm) {
    gap: rem(16px);
    padding: rem(16px);
  }

  &__top {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: rem(8px);
  }

  &__satus-label-icon {
    display: flex;
    align-items: center;
    gap: rem(8px);
    width: 100%;
  }

  &__satus-icon {
    width: rem(20px);
    height: rem(20px);
  }

  &__satus-label {
    font-size: rem(14px);
    line-height: 1;
    font-weight: 400;
    letter-spacing: 0.04em;
    color: var(--color-neutral-50);
  }

  &__title-btn {
    display: flex;
    align-items: flex-start;
    gap: rem(16px);
  }

  &__title {
    position: relative;
    width: 100%;
    font-size: rem(16px);
    font-weight: 600;
    color: var(--color-neutral-30);

    &::first-letter {
      text-transform: uppercase;
    }

    &--large {
      font-size: 24px;
    }
  }

  &__open-btn {
    display: flex;
    flex-shrink: 0;
    position: relative;
    z-index: 1;
  }

  &__bottom {
    display: flex;
    align-items: flex-end;
    gap: rem(16px);
    width: 100%;

    @include breakpoint-max(lg) {
      flex-direction: column;
      align-items: flex-start;
    }
  }

  &__flex-column {
    display: flex;
    flex-direction: column;
    gap: rem(12px);
    width: 100%;

    &--small {
      max-width: rem(300px);
    }
  }

  &__status {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: fit-content;
    gap: rem(32px);

    &--w-100 {
      width: 100%;
    }
  }

  &__countdown {
    font-size: rem(14px);
    font-weight: 500;
    color: var(--color-neutral-50);
  }

  &__deadline {
    position: relative;
    font-size: rem(14px);
    font-weight: 500;
    letter-spacing: 0.04em;
    color: var(--color-neutral-50);
    transition: color 0.3s;

    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: rem(-17.5px);
      display: block;
      height: 100%;
      width: 2px;
      border-radius: 999px;
      background-color: var(--color-neutral-80);
    }
  }

  &__progress-bar {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    height: 4px;
    border-radius: 999px;
  }

  &__bar {
    position: absolute;
    z-index: 2;
    left: 0;
    top: 0;
    display: block;
    width: 100%;
    height: 100%;
    border-radius: inherit;
    background-color: var(--color-neutral-80);
    transition: width 1s;

    &--bg {
      z-index: 1;
      width: 100%;
      background-color: var(--color-neutral-80) !important;
    }
  }

  &__btn {
    z-index: 1;
    flex-shrink: 0;
  }

  &--pending,
  &--inprogress {
    @include breakpoint-min(md) {
      &:hover {
        background-color: var(--color-primary-90);
      }
    }

    #{$block} {
      &__satus-icon {
        color: var(--color-primary-60);
      }

      &__deadline {
        color: var(--color-primary-40);
      }

      &__bar {
        background-color: var(--color-primary-60);
      }
    }
  }

  &--finished {
    @include breakpoint-min(md) {
      &:hover {
        background-color: var(--color-success-90);
      }
    }

    #{$block} {
      &__satus-icon {
        color: var(--color-success-60);
      }

      &__deadline {
        color: var(--color-success-40);
      }

      &__bar {
        background-color: var(--color-success-60);
      }
    }
  }

  &--expired {
    @include breakpoint-min(md) {
      &:hover {
        background-color: var(--color-error-90);
      }
    }

    #{$block} {
      &__satus-icon {
        color: var(--color-error-60);
      }

      &__deadline {
        color: var(--color-error-40);
      }

      &__bar {
        background-color: var(--color-error-60);
      }
    }
  }

  &--fade-in-out {
    animation: fadeInOut 1000ms linear;
  }

  &--disabled {
    pointer-events: none;
  }
}

@keyframes fadeInOut {
  0% {
    background-color: var(--color-neutral-100);
  }
  50% {
    background-color: var(--color-plus-3);
  }
  0% {
    background-color: var(--color-neutral-100);
  }
}
