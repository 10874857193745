$block: '.tabs';
$tabs-btn-padding-horizontal: rem(32px);
$tabs-btn-padding-horizontal--xl: rem(24px);

#{$block} {
  position: relative;
  display: block;
  height: 100%;
  width: 100%;

  &__overflow-container {
    overflow: hidden;
    overflow-x: auto;
    display: flex;
    gap: rem(16px);
    height: 100%;
    width: 100%;
    padding: $tabs-padding;

    @include breakpoint-max(md) {
      gap: rem(12px);
      padding-bottom: rem(16px);
    }

    @include breakpoint-max(sm) {
      gap: rem(8px);
    }
  }

  &__btn {
    display: flex;
    flex-shrink: 0;
    white-space: nowrap;
    width: fit-content;
    border-radius: $br--small;

    &--suggested {
      order: -1;
    }
  }

  &__text {
    display: block;
    font-size: inherit;
    line-height: inherit;
    font-weight: inherit;
    color: inherit;

    &::first-letter {
      text-transform: uppercase;
    }
  }

  &__border-bottom {
    position: absolute;
    bottom: 0;
    left: $tabs-btn-padding-horizontal;
    right: $tabs-btn-padding-horizontal;
    display: block;
    height: rem(4px);
    background-color: var(--color-primary-40);
    border-top-left-radius: 999px;
    border-top-right-radius: 999px;
    opacity: 0;
    transition: background-color 0.2s;
        
    @include breakpoint-max(xl) {
      padding-left: $tabs-btn-padding-horizontal--xl;
      padding-right: $tabs-btn-padding-horizontal--xl;
    }
  }

  &--heading {
    #{$block} {
      &__overflow-container {
        padding: 0;
      }

      &__btn {
        height: 100%;
        min-height: unset;
        padding-left: $tabs-btn-padding-horizontal;
        padding-right: $tabs-btn-padding-horizontal;
        color: var(--color-neutral-40) !important;
        background-color: unset !important;
        border: unset !important;
        outline: unset !important;
        
        @include breakpoint-min(md) {
          &:hover {
            background-color: var(--color-neutral-80) !important;
          }
        }
        
        @include breakpoint-max(xl) {
          padding-left: $tabs-btn-padding-horizontal--xl;
          padding-right: $tabs-btn-padding-horizontal--xl;
        }
      }
    }

    .btn--active {
      overflow: hidden;
      color: var(--color-neutral-10) !important;
      
      &:after {
        --color-opacity: .15;

        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: var(--color-primary-40) !important;
      }

      #{$block} {
        &__btn {
          height: 100%;
          min-height: unset;
          padding-left: $tabs-btn-padding-horizontal;
          padding-right: $tabs-btn-padding-horizontal;
          color: var(--color-neutral-40) !important;
          background-color: unset !important;
          border: unset !important;
          outline: unset !important;
          
          @include breakpoint-min(md) {
            &:hover {
              background-color: var(--color-neutral-80) !important;
            }
          }
          
          @include breakpoint-max(xl) {
            padding-left: $tabs-btn-padding-horizontal--xl;
            padding-right: $tabs-btn-padding-horizontal--xl;
          }
        }
      }
    }
  }

  &--payment-methods {
    .img-icon {
      height: 17px;
      width: 17px;
    }

    .btn {
      width: 100%;
      flex: 1;
      &--active {
        background-color: var(--color-primary-40) !important;
        color: var(--color-neutral-100) !important;
        border-color: var(--color-primary-40) !important;

        .btn__icon-img {
          filter: brightness(0) invert(1);
        }
      }
    }

    #{$block} {
      &__overflow-container {
        overflow: visible;
        padding: 0;

        @include breakpoint-max(sm) {
          flex-direction: column;
        }
      }
    }
  }

  &--flex-wrap {
    #{$block} {
      &__overflow-container {
        overflow: initial;
        flex-wrap: wrap;
        width: 100%;
        padding: 0;
        gap: rem(12px);

        .btn {
          @include breakpoint-max(md) {
            flex-grow: 1;
          }
        }
      }
    }
  }
}
