$block: '.card-download';

#{$block} {
  display: flex;
  flex-direction: column;
  gap: rem(8px);

  &__top {
  }

  &__text-icon {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: rem(16px);
  }

  &__text {
    display: block;
    width: 100%;
    font-size: rem(14px);
    line-height: 1.4;
    text-align: center;

    &--emphasys {
      font-size: 16px;
      font-weight: 600;
    }
  }

  &__small {
    display: block;
    width: 100%;
    font-size: rem(12px);
    text-align: center;
  }

  &__icon {
    width: rem(32px);
    height: rem(32px);
  }

  &__bottom {
    width: 100%;
  }

  &__btn {
    background-color: var(--color-neutral-90) !important;

    &:hover {
      --tw-border-opacity: 1 !important;
      border-color: hsl(192 80% 35% / var(--tw-border-opacity)) !important;
      --tw-bg-opacity: 1 !important;
      background-color: hsl(192 80% 35% / var(--tw-bg-opacity)) !important;
      --tw-text-opacity: 1 !important;
      color: hsl(0 0% 96% / var(--tw-text-opacity)) !important;
    }

    &:active {
      --tw-border-opacity: 1 !important;
      border-color: hsl(192 85% 25% / var(--tw-border-opacity)) !important;
      --tw-bg-opacity: 1 !important;
      background-color: hsl(192 85% 25% / var(--tw-bg-opacity)) !important;
      --tw-text-opacity: 1 !important;
      color: hsl(0 0% 96% / var(--tw-text-opacity)) !important;
    }

    &:disabled {
      background-color: var(--color-neutral-80)!important;
      border-color: var(--color-neutral-80)!important;
    }
  }
}
