.w-full {
  width: 100%;
}

.progressive-image {
  filter: blur(20px);
}

.fadeIn {
  animation-name: fadeIn;
  animation-duration: 1500ms;
}

@keyframes fadeIn {
  0% { opacity: 1; }
  20% { opacity: 0; }
  100% { opacity: 1; }
}

/*sekeleton loader*/
.skeleton {
  background: linear-gradient(0.25turn, transparent, var(--color-neutral-90), transparent),
  linear-gradient(var(--color-neutral-80), var(--color-neutral-80)),
  radial-gradient(38px circle at 19px 19px, var(--color-neutral-80) 50%, transparent 51%),
  linear-gradient(0.25turn, transparent, var(--color-neutral-90), transparent) ;
  background-repeat: no-repeat ;
  background-size: 100% ;
  animation: skeleton-loading 1.5s infinite !important;
  outline: none !important;
  border: none !important;
  box-shadow: none !important;
  pointer-events: none !important;
  color: transparent !important;
  transition: none !important;
  border-radius: 6px !important;
  user-select: none !important;
  
  * {
    opacity: 0 !important;
    color: transparent !important;
    transition: none !important;
  }
  
  &--neutral-100 {
    background: linear-gradient(0.25turn, transparent, var(--color-neutral-100), transparent),
    linear-gradient(var(--color-neutral-90), var(--color-neutral-90)),
    radial-gradient(38px circle at 19px 19px, var(--color-neutral-90) 50%, transparent 51%),
    linear-gradient(0.25turn, transparent, var(--color-neutral-100), transparent) ;
  }
  
  &--neutral-80 {
    background: linear-gradient(0.25turn, transparent, var(--color-neutral-80), transparent),
    linear-gradient(var(--color-neutral-70), var(--color-neutral-70)),
    radial-gradient(38px circle at 19px 19px, var(--color-neutral-70) 50%, transparent 51%),
    linear-gradient(0.25turn, transparent, var(--color-neutral-80), transparent) ;
  }
}

@keyframes skeleton-loading {
  to {
    background-position: 315px 0, 0 0, 0 190px, 50px 195px;
  }
}