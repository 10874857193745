$image-viewer-max-width: rem(1900px);
$image-viewer-max-height: 95vh;

$image-viewer-btn-color: var(--color-neutral-30);
$image-viewer-btn-hover: var(--color-neutral-80);
$image-viewer-btn-size: rem(40px);
$image-viewer-btn-icon-size: rem(24px);
$image-viewer-arrow-gap: $page-container-gap;

$image-viewer-container-gap: rem(16px);

$image-viewer-pd-horizontal: rem(140px);
$image-viewer-pd-horizontal--xxl: rem(110px);
$image-viewer-pd-horizontal--lg: rem(50px);
$image-viewer-pd-horizontal--sm: rem(25px);
$image-viewer-pd-vertical: rem(24px);

$image-viewer-img-border: $br;

$image-viewer-actions-height: calc($image-viewer-btn-size + $image-viewer-container-gap);
