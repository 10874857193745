// font family
$form-font-family: $btn-font-family;
$form-font-family: $btn-font-family;

// transition
$form-transition-timing: $btn-transition-timing;
$form-transition-time-function: $btn-transition-time-function;
$form-transition: background-color $form-transition-timing
    $form-transition-time-function,
  border-color $form-transition-timing $form-transition-time-function,
  color $form-transition-timing $form-transition-time-function,
  outline-color $form-transition-timing $form-transition-time-function;

//  REGULAR SIZE  //  REGULAR SIZE  //  REGULAR SIZE  //
//  REGULAR SIZE  //  REGULAR SIZE  //  REGULAR SIZE  //

//form gaps
$form-gap: $page-container-gap;
$form-gap-grid: rem(24px);
$form-gap-grid--xxl: rem(16px);
$form-gap-row: $form-gap-grid;
$form-gap-row--xxl: $form-gap-grid--xxl;
$form-gap-group: rem(10px);
$form-gap-group--xxl: rem(6px);
//borders sizing
$form-border-width: 1px;
$form-border-radius: $br--small;
//font sizing
$form-font-size: $btn-font-size;
$form-label-font-size: $form-font-size;
$form-placeholder-font-size: $form-font-size;
$form-line-height: $btn-line-height;
$form-label-line-height: $form-line-height;
$form-letter-spacing: 0;
$form-font-weight: 400;
$form-text-transform: unset;
//general sizing
$form-padding-top: $btn-padding-top;
$form-padding-bottom: $form-padding-top;
$form-padding-top--mesage: rem(16px);
$form-padding-bottom--mesage: $form-padding-top--mesage;
$form-padding-right: calc(
  $form-padding-top + (($form-line-height - $form-font-size) / 2)
);
$form-padding-left: calc(
  $form-padding-top + (($form-line-height - $form-font-size) / 2)
);
$form-height: calc(
  $form-line-height + $form-padding-top + $form-padding-bottom + ($form-border-width * 2)
);
//outline sizing
$form-ring-width: 4px;
$form-ring-offset: 0px;
//icons sizing
$form-icon-size: $form-line-height;
$form-padding-icon: calc(($form-padding-right * 2) + $form-icon-size);
$form-top-position-icon: calc($form-label-line-height + $form-gap-group);
//boxshadow
$form-bs--message: $bs-message;

//  LARGE SIZE  //  LARGE SIZE  //  LARGE SIZE  //
//  LARGE SIZE  //  LARGE SIZE  //  LARGE SIZE  //
//borders sizing
$form-border-width--large: 1px;
$form-border-radius--large: $br--small;
//font sizing
$form-font-size--large: $btn-font-size--large;
$form-label-font-size--large: $form-font-size--large;
$form-placeholder-font-size--large: $form-font-size--large;
$form-line-height--large: $btn-line-height--large;
$form-label-line-height--large: $form-line-height--large;
$form-letter-spacing--large: 0;
$form-font-weight--large: 400;
$form-text-transform--large: unset;
//general sizing
$form-padding-top--large: $btn-padding-top--large;
$form-padding-bottom--large: $form-padding-top--large;
$form-padding-right--large: calc(
  $form-padding-top--large +
    (($form-line-height--large - $form-font-size--large) / 2)
);
$form-padding-left--large: calc(
  $form-padding-top--large +
    (($form-line-height--large - $form-font-size--large) / 2)
);
$form-height--large: calc(
  $form-line-height--large + $form-padding-top--large +
    $form-padding-bottom--large + ($form-border-width--large * 2)
);
//outline sizing
$form-ring-width--large: 4px;
$form-ring-offset--large: 0px;
//icons sizing
$form-icon-size--large: $form-line-height--large;
$form-padding-icon--large: calc(
  ($form-padding-right--large * 2) + $form-icon-size--large
  );
  $form-top-position-icon--large: calc(
    $form-label-line-height--large + $form-gap-group
    );
    
//  SMALL SIZE  //  SMALL SIZE  //  SMAL SIZE  //
//  SMALL SIZE  //  SMALL SIZE  //  SMAL SIZE  //
//borders sizing
$form-border-width--small: 1px;
$form-border-radius--small: $br--small;
//font sizing
$form-font-size--small: $btn-font-size--small;
$form-label-font-size--small: $btn-font-size--small;
$form-placeholder-font-size--small: $form-font-size--small;
$form-line-height--small: $btn-line-height--small;
$form-label-line-height--small: $btn-line-height--small;
$form-letter-spacing--small: 0.01em;
$form-font-weight--small: 400;
$form-text-transform--small: unset;
//general sizing
$form-padding-top--small: $btn-padding-top--small;
$form-padding-bottom--small: $form-padding-top--small;
$form-padding-right--small: calc(
  $form-padding-top--small +
    (($form-line-height--small - $form-font-size--small) / 2)
);
$form-padding-left--small: calc(
  $form-padding-top--small +
    (($form-line-height--small - $form-font-size--small) / 2)
);
$form-height--small: calc(
  $form-line-height--small + $form-padding-top--small +
    $form-padding-bottom--small + ($form-border-width--small * 2)
);
//icons sizing
$form-icon-size--small: $form-line-height--small;
$form-padding-icon--small: calc(
  ($form-padding-right--small * 2) + $form-icon-size--small
);
$form-top-position-icon--small: calc(
  $form-label-line-height--small + $form-gap-group
);
//outline sizing
$form-ring-width--small: 4px;
$form-ring-offset--small: 0px;

//  THEME NEUTRAL  //  THEME NEUTRAL  //  THEME NEUTRAL  //
//  THEME NEUTRAL  //  THEME NEUTRAL  //  THEME NEUTRAL  //
// theme neutral
$form-color-bg: var(--color-neutral-100);
$form-color-bg--message: var(--color-neutral-90);
$form-color-border: var(--color-neutral-70);
$form-color-font: var(--color-neutral-40);
$form-placeholder-color-font: var(--color-neutral-60);
$form-color-ring: var(--color-primary-80);
// theme neutral __active
$form-color-bg__active: var(--color-neutral-100);
$form-color-border__active: var(--color-neutral-60);
$form-color-font__active: var(--color-neutral-40);
$form-color-ring__active: var(--color-neutral-80);

//  THEME SUCCESS  //  THEME SUCCESS  //  THEME SUCCESS  //
//  THEME SUCCESS  //  THEME SUCCESS  //  THEME SUCCESS  //
// theme --success
$form-color-bg--success: var(--color-neutral-100);
$form-color-bg--message--success: var(--color-success-80);
$form-color-border--success: var(--color-primary-50);
$form-color-font--success: var(--color-neutral-40);
$form-color-ring--success: var(--color-primary-80);
$form-bs--message--success: $bs-message--success;

//  THEME ERROR  //  THEME ERROR  //  THEME ERROR  //
//  THEME ERROR  //  THEME ERROR  //  THEME ERROR  //
// theme --error
$form-color-bg--error: var(--color-neutral-100);
$form-color-bg--message--error: var(--color-error-80);
$form-color-border--error: var(--color-error-50);
$form-color-font--error: var(--color-neutral-40);
$form-color-ring--error: var(--color-error-80);
$form-bs--message--error: $bs-message--error;

// disabled
$form-color-bg__disabled: var(--color-neutral-80);
$form-color-border__disabled: var(--color-neutral-80);
$form-color-font__disabled: var(--color-neutral-60);
$form-placeholder-color-font__disabled: var(--color-neutral-60);
$form-color-ring__disabled: transparent;
