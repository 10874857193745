$block: '.comments-chat';

#{$block} {
  $comments-input-height: $form-height;
  $text-drop-shadow: drop-shadow(1px 1px 2px rgba(0, 0, 0, 0.2))
    drop-shadow(1px 1px 2px rgba(0, 0, 0, 0.2));

  overflow: hidden;
  position: relative;
  z-index: 1;
  height: 100%;
  width: 100%;
  border-radius: $br;
  transform: translateY(0);
  opacity: 1;
  transition: transform 0.3s, opacity 0.3s;

  &__inner {
    display: flex;
    flex-direction: column;
    gap: rem(16px);
    height: 100%;
    width: 100%;
    padding: rem(16px);
    padding-bottom: rem(24px);
    background-color: var(--color-neutral-100);

    @include breakpoint-max(xxl) {
      gap: rem(12px);
      padding: rem(12px) rem(16px);
      padding-bottom: rem(16px);
    }
  }

  &__heading {
    display: flex;
    align-items: center;
    flex-shrink: 0;
    gap: rem(10px);
    width: 100%;
    padding: 0 rem(8px);
  }

  &__left {
    overflow: hidden;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: rem(40px);
    max-width: rem(40px);
    width: 100%;
    min-height: rem(40px);
    max-height: rem(40px);
    height: 100%;
    border-radius: $br--xsmall;

    @include breakpoint-max(xxl) {
      min-width: rem(32px);
      max-width: rem(32px);
      min-height: rem(32px);
      max-height: rem(32px);
    }
  }

  &__img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }

  &__middle {
    display: flex;
    align-items: center;
    gap: rem(10px);
    height: 100%;
    width: 100%;
  }

  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    height: rem(24px);
    width: rem(24px);

    @include breakpoint-max(xxl) {
      height: rem(20px);
      width: rem(20px);
    }

    &--emphasys {
      color: var(--color-primary-40);
    }

    &--lg {
      height: rem(32px);
      width: rem(32px);
      padding: 0 !important;

      @include breakpoint-max(xxl) {
        height: rem(24px);
        width: rem(24px);
      }
    }
  }

  &__title {
    font-size: rem(22px);
    line-height: 1.2;
    font-weight: 600;
    color: var(--color-neutral-30);

    @include breakpoint-max(xxl) {
      font-size: rem(18px);
    }
  }

  &__right {
    @extend #{$block}__left;

    &--page {
      display: flex;
      flex-shrink: 0;
      min-width: unset !important;
      max-width: unset !important;
      min-height: unset !important;
      max-height: unset !important;
      width: fit-content;
    }
  }

  &__page {
    @extend #{$block}__title;
    font-size: rem(14px) !important;
    font-weight: 500;
  }

  &__btn {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    max-width: $comments-input-height;
    min-width: $comments-input-height;
    padding: 0 !important;
    transition: background-color 0.2s;

    @include breakpoint-min(md) {
      &:hover {
        background-color: var(--color-neutral-80);
      }
    }
  }

  &__input-btn {
    flex-shrink: 0;
  }

  &__messages-container {
    overflow-y: auto;
    position: relative;
    display: flex;
    flex-direction: column;
    flex: 1;
    gap: rem(16px);
    width: 100%;
    padding: rem(16px);
    padding-right: rem(20px);
    border-radius: $br;
    background-color: var(--color-neutral-80);
  }

  &__message {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: rem(16px);
    width: 80%;
    padding: rem(12px);
    margin-right: auto;
    border-radius: $br;
    border-bottom-left-radius: 0;
    background-color: var(--color-primary-30);

    @include breakpoint-max(xxl) {
      padding: rem(8px);
      gap: rem(8px);
    }

    &:after {
      content: '';
      position: absolute;
      right: 100%;
      bottom: 0;
      display: block;
      width: 0;
      height: 0;
      border-bottom: rem(10px) solid var(--color-primary-30);
      border-left: rem(10px) solid transparent;
    }

    &--own {
      background-color: var(--color-primary-50);
      margin-right: 0;
      margin-left: auto;
      color: var(--color-neutral-100);
      border-bottom-left-radius: $br;
      border-bottom-right-radius: 0;

      &:after {
        right: unset;
        left: 100%;
        border-bottom: rem(10px) solid var(--color-primary-50);
        border-left: unset;
        border-right: rem(10px) solid transparent;
      }

      #{$block} {
        &__author {
          &::before {
            content: 'Você';
          }
        }
      }
    }

    &.skeleton {
      &:after {
        display: none;
      }
    }
  }

  &__text {
    font-size: rem(16px);
    font-weight: 400;
    letter-spacing: 0.04em;
    line-height: 1.3;
    color: var(--color-neutral-100);
    filter: $text-drop-shadow;

    @include breakpoint-max(xxl) {
      font-size: rem(14px);
    }
  }

  &__infos {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    gap: rem(16px);
    filter: $text-drop-shadow;
  }

  &__author {
    display: block;
    width: fit-content;
    height: fit-content;

    &::before {
      content: 'Fotógrafo';
      font-size: rem(12px);
      font-weight: 500;
      line-height: 1;
      letter-spacing: 0.04em;
      color: var(--color-neutral-100);
    }
  }

  &__time {
    font-size: rem(10px);
    line-height: 1;
    font-weight: 500;
    letter-spacing: 0.08em;
    color: var(--color-neutral-100);
  }

  &__input {
    display: flex;
    gap: rem(8px);
    width: 100%;
  }

  &__empty {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    font-size: rem(16px);
    color: var(--color-neutral-30);
    font-weight: 500;
    text-align: center;
  }

  &__input-field {
    width: 100%;
    min-width: unset;
  }

  &__last-message-anchor {
    display: block;
    width: 0px;
    height: 0px;
  }

  &--image-selection {
    position: fixed;
    z-index: $zindex-dialog--3;
    right: $page-container-pd-horizontal;
    bottom: 0px;
    max-width: rem(400px);
    height: rem(480px);
    max-height: 70vh;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    box-shadow: 0 -10px 20px rgba(0, 0, 0, 0.1);

    @include breakpoint-max(xxl) {
      right: $page-container-pd-horizontal--xxl;
    }

    @include breakpoint-max(xl) {
      right: $page-container-pd-horizontal--xl;
    }

    @include breakpoint-max(lg) {
      right: $page-container-pd-horizontal--lg;
    }

    @include breakpoint-max(sm) {
      right: $page-container-pd-horizontal--sm;
    }
  }

  &--album-approval {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    height: 100%;

    #{$block} {
      &__title {
        font-size: rem(16px);
      }

      &__left {
        @include breakpoint-max(xxl) {
          min-width: rem(24px);
          max-width: rem(24px);
          min-height: rem(24px);
          max-height: rem(24px);
        }
      }
    }
  }

  &--hidden {
    transform: translateY(100%);
    opacity: 0;
  }
}
