$block: '.orders';

#{$block} {
  position: relative;
  width: 100%;

  &__grid {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    gap: rem(32px);

    @include breakpoint-max(xl) {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }

    @include breakpoint-max(sm) {
      grid-template-columns: repeat(1, minmax(0, 1fr));
    }
    
    &--column-1 {
      grid-template-columns: repeat(1, minmax(0, 1fr));
    }
  }
}
