$block: '.login';

#{$block} {
  &__btn-group {
    display: grid;
    gap: rem(16px);
    padding-top: rem(8px);

    @include breakpoint-max(xl) {
      gap: rem(8px);
    }
  }

  &__link {
    font-size: rem(14px);
    line-height: rem(20px);
    font-weight: 500;
    letter-spacing: 0.04em;
    color: var(--color-primary-50);
    transition: color 0.2s;

    @include breakpoint-min(md) {
      &:hover {
        color: var(--color-primary-10);
      }
    }
  }
}
