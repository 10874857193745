$block: '.reset';

#{$block} {
  &__area {
    max-width: rem(496px);
    padding: rem(24px);
  }

  &__link {
    font-size: rem(14px);
    line-height: rem(20px);
    font-weight: 500;
    letter-spacing: 0.04em;
    color: var(--color-primary-50);
    transition: color 0.2s;

    @include breakpoint-min(md) {
      &:hover {
        color: var(--color-primary-10);
      }
    }
  }
}
