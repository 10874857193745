:root,
*:after,
*:before {
  --color-primary: hsl(192, 80%, 35%);
  --color-primary-hue: 192;
  --color-white: white;
  --color-black: black;

  --color-opacity: 1;

  --color-neutral-100: hsla(0, 0%, 100%, var(--color-opacity));
  --color-neutral-95: hsla(0, 0%, 98%, var(--color-opacity));
  --color-neutral-90: hsla(0, 0%, 96%, var(--color-opacity));
  --color-neutral-80: hsla(0, 0%, 90%, var(--color-opacity));
  --color-neutral-70: hsla(0, 0%, 80%, var(--color-opacity));
  --color-neutral-60: hsla(0, 0%, 68%, var(--color-opacity));
  --color-neutral-50: hsla(0, 0%, 50%, var(--color-opacity));
  --color-neutral-40: hsla(0, 0%, 38%, var(--color-opacity));
  --color-neutral-30: hsla(0, 0%, 21%, var(--color-opacity));
  --color-neutral-20: hsla(0, 0%, 13%, var(--color-opacity));
  --color-neutral-10: hsla(0, 0%, 9%, var(--color-opacity));

  --color-success-100: hsla(146, 25%, 97%, var(--color-opacity));
  --color-success-90: hsla(146, 25%, 93%, var(--color-opacity));
  --color-success-80: hsla(146, 45%, 85%, var(--color-opacity));
  --color-success-70: hsla(146, 45%, 75%, var(--color-opacity));
  --color-success-60: hsla(146, 50%, 65%, var(--color-opacity));
  --color-success-50: hsla(146, 55%, 65%, var(--color-opacity));
  --color-success-40: hsla(146, 80%, 35%, var(--color-opacity));
  --color-success-30: hsla(146, 85%, 25%, var(--color-opacity));
  --color-success-20: hsla(146, 90%, 15%, var(--color-opacity));
  --color-success-10: hsla(146, 95%, 10%, var(--color-opacity));

  --color-error-100: hsla(348, 25%, 97%, var(--color-opacity));
  --color-error-90: hsla(348, 25%, 93%, var(--color-opacity));
  --color-error-80: hsla(348, 45%, 85%, var(--color-opacity));
  --color-error-70: hsla(348, 45%, 75%, var(--color-opacity));
  --color-error-60: hsla(348, 50%, 65%, var(--color-opacity));
  --color-error-50: hsla(348, 55%, 65%, var(--color-opacity));
  --color-error-40: hsla(348, 80%, 35%, var(--color-opacity));
  --color-error-30: hsla(348, 85%, 25%, var(--color-opacity));
  --color-error-20: hsla(348, 90%, 15%, var(--color-opacity));
  --color-error-10: hsla(348, 95%, 10%, var(--color-opacity));

  --color-primary-100: hsla(var(--color-primary-hue), 25%, 97%, var(--color-opacity));
  --color-primary-90: hsla(var(--color-primary-hue), 25%, 93%, var(--color-opacity));
  --color-primary-80: hsla(var(--color-primary-hue), 45%, 85%, var(--color-opacity));
  --color-primary-70: hsla(var(--color-primary-hue), 45%, 75%, var(--color-opacity));
  --color-primary-60: hsla(var(--color-primary-hue), 50%, 65%, var(--color-opacity));
  --color-primary-50: hsla(var(--color-primary-hue), 55%, 56%, var(--color-opacity));
  --color-primary-40: hsla(var(--color-primary-hue), 80%, 35%, var(--color-opacity));
  --color-primary-30: hsla(var(--color-primary-hue), 85%, 25%, var(--color-opacity));
  --color-primary-20: hsla(var(--color-primary-hue), 90%, 15%, var(--color-opacity));
  --color-primary-10: hsla(var(--color-primary-hue), 95%, 10%, var(--color-opacity));
}

.theme--deep-blue,
.theme--deep-blue *:after,
.theme--deep-blue *:before {
  --color-primary: hsl(219.8, 80%, 35%);
  --color-primary-hue: 219.8;
}

.theme--may-green,
.theme--may-green *:after,
.theme--may-green *:before {
  --color-primary: hsl(127.2, 80%, 35%);
  --color-primary-hue: 127.2;
}

.theme--magenta,
.theme--magenta *:after,
.theme--magenta *:before {
  --color-primary: hsl(339.8, 80%, 35%);
  --color-primary-hue: 339.8;
}

.theme--metallic-sunburst,
.theme--metallic-sunburst *:after,
.theme--metallic-sunburst *:before {
  --color-primary: hsl(45, 80%, 35%);
  --color-primary-hue: 45;
}

.theme--granite-grey,
.theme--granite-grey *:after,
.theme--granite-grey *:before {
  --color-primary: hsl(0, 0, 35%);
  --color-primary-hue: 0;

  --color-primary-100: hsla(var(--color-primary-hue), 0, 97%, var(--color-opacity));
  --color-primary-90: hsla(var(--color-primary-hue), 0, 93%, var(--color-opacity));
  --color-primary-80: hsla(var(--color-primary-hue), 0, 85%, var(--color-opacity));
  --color-primary-70: hsla(var(--color-primary-hue), 0, 75%, var(--color-opacity));
  --color-primary-60: hsla(var(--color-primary-hue), 0, 65%, var(--color-opacity));
  --color-primary-50: hsla(var(--color-primary-hue), 0, 56%, var(--color-opacity));
  --color-primary-40: hsla(var(--color-primary-hue), 0, 35%, var(--color-opacity));
  --color-primary-30: hsla(var(--color-primary-hue), 0, 25%, var(--color-opacity));
  --color-primary-20: hsla(var(--color-primary-hue), 0, 15%, var(--color-opacity));
  --color-primary-10: hsla(var(--color-primary-hue), 0, 10%, var(--color-opacity));
}
