$block: '.download-request';

#{$block} {
  $card-max-width: 100%;
  $card-container-gap: $page-container-gap;
  $card-container-width: calc(($card-max-width * 2) + rem(24px));
  position: relative;

  &__column {
    display: flex;
    flex-direction: column;
    gap: $card-container-gap;
    max-width: $card-container-width;
    margin-left: auto;
    margin-right: auto;
  }

  &__top-wrapper {
    gap: rem(8px);
  }

  &__row {
    display: flex;
    gap: $card-container-gap;
  }

  &__card-wrapper {
    display: block;
    width: 100%;
    max-width: $card-max-width;
  }
}
