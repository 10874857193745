$block: '.hamburguer-menu';

#{$block} {
  position: relative;
  display: flex;
  height: $header-hamburguer-menu-size;
  width: $header-hamburguer-menu-size;
  flex-shrink: 0;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-color: unset;
  background-color: unset;
  padding: 0px;
  transition: transform 0.5s;

  &__line {
    position: absolute;
    height: 2px;
    width: rem(32px);
    border-radius: 9999px;
    --tw-bg-opacity: 1;
    background-color: hsl(0 0% 38% / var(--tw-bg-opacity));
    transition: transform 0.5s ease, opacity 0.5s ease, width 0.5s ease;

    &--top {
      top: 33.333333%;
    }

    &--bottom {
      bottom: 33.333333%;
    }
  }
}

.page__html--offcanvas-is-open {
  #{$block} {
    z-index: 40;

    &__line {
      &--top {
        top: 50%;
        transform: rotate(-135deg) translateY(50%);
      }

      &--middle {
        width: 0px;
      }

      &--bottom {
        bottom: 50%;
        transform: rotate(135deg) translateY(-50%);
      }
    }
  }
}
