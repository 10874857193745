// OVERALL DIMENSIONS
$modal-pd-vertical: rem(40px);
$modal-pd-horizontal: $page-container-pd-horizontal;
$modal-pd-horizontal--xxl: $page-container-pd-horizontal--xxl;
$modal-pd-horizontal--xl: $page-container-pd-horizontal--xl;
$modal-pd-horizontal--lg: $page-container-pd-horizontal--lg;
$modal-pd-horizontal--sm: $page-container-pd-horizontal--sm;

// BACKDROP
$modal-backdrop-color: $backdrop-color;
$modal-backdrop-opacity: $backdrop-opacity;

// WRAPPER
$modal-wrapper-max-width: rem(1420px);
$modal-wrapper-max-height: 90vh;

// BODY
$modal-body-pd-vertical: rem(24px);
$modal-body-pd-vertical--xxl: rem(24px);
$modal-body-pd-vertical--sm: rem(24px);
$modal-body-pd-horizontal: rem(32px);
$modal-body-pd-horizontal--xxl: rem(32px);
$modal-body-pd-horizontal--sm: rem(24px);

// ICON
$modal-icon-size: rem(32px);
$modal-icon-size--xxl: rem(32px);
$modal-icon-size--sm: rem(32px);

// TITLE
$modal-title-fs: rem(16px);

// HEADER
$modal-header-total-height: calc(4 * $modal-body-pd-vertical + $modal-icon-size);
$modal-header-total-height--xxl: calc(4 * $modal-body-pd-vertical--xxl + $modal-icon-size--xxl);
$modal-header-total-height--sm: calc(4 * $modal-body-pd-vertical--sm + $modal-icon-size--sm);
