$block: '.custom-scrollbar';

#{$block} {
  @include breakpoint-min(md) {
    &::-webkit-scrollbar {
      width: $scrollbar-width;
      height: $scrollbar-width;
    }

    &::-webkit-scrollbar-track {
      background-image: linear-gradient(
        90deg,
        $scrollbar-track-bg-color--1 0%,
        $scrollbar-track-bg-color--2 100%
      );
      border: $scrollbar-track-border;
      border-radius: $scrollbar-border-radius;
    }

    &::-webkit-scrollbar-thumb {
      background-image: linear-gradient(
        180deg,
        $scrollbar-thumb-bg-color--1 0%,
        $scrollbar-thumb-bg-color--2 50%,
        $scrollbar-thumb-bg-color--1 100%
      );
      border-radius: $scrollbar-border-radius;
    }
  }
}
