$block: '.user-settings';

#{$block} {
  &__forms-area {
    display: block;
  }

  &__flex-container {
    display: flex;
    gap: $page-container-gap;

    @include breakpoint-max(md) {
      flex-direction: column;
    }
  }

  &__forms-wrapper {
    display: flex;
    flex: 1;
    width: 100%;
    gap: $page-container-gap;
  }

  &__left {
    display: flex;
    flex-direction: column;
    flex: 1;
    gap: $page-container-gap;
  }

  &__form-wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: $page-container-gap;
    width: 100%;
    padding: rem(24px);
    border: 1px solid var(--color-neutral-80);
    border-radius: $br;
    background-color: var(--color-neutral-100);

    @include breakpoint-max(xxl) {
      gap: $page-container-gap;
    }

    @include breakpoint-max(sm) {
      gap: rem(16px);
    }
  }

  &__subtitle {
    font-size: rem(20px);
    line-height: rem(24px);
    font-weight: 500;
    color: var(--color-primary-40);
  }

  &__user-profile-form {
  }

  &__user-profile-grid {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(3, 1fr);

    .form__group:nth-of-type(1) {
      grid-area: 1 / 1 / 2 / 3;
    }
    .form__group:nth-of-type(2) {
      grid-area: 2 / 1 / 4 / 2;
    }
    .form__group:nth-of-type(3) {
      grid-area: 2 / 2 / 4 / 3;
    }    
    .form__group:nth-of-type(4) {
      grid-area: 3 / 1 / 4 / 2;
    }
    .form__group:nth-of-type(5) {
      grid-area: 3 / 2 / 4 / 3;
    }
  }

  &__right {
    @extend #{$block}__left;
  }

  &__user-acess-info {
    display: grid;
    grid-template-columns: repeat(1, minmax(0,1fr));
  }

  &__user-adress-grid {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(5, 1fr);

    .form__group:nth-of-type(1) {
      grid-area: 1 / 1 / 2 / 3;
    }
    .form__group:nth-of-type(2) {
      grid-area: 2 / 1 / 3 / 2;
    }
    .form__group:nth-of-type(3) {
      grid-area: 2 / 2 / 3 / 3;
    }
    .form__group:nth-of-type(4) {
      grid-area: 3 / 1 / 4 / 3;
    }
    .form__group:nth-of-type(5) {
      grid-area: 4 / 1 / 5 / 2;
    }
    .form__group:nth-of-type(6) {
      grid-area: 4 / 2 / 5 / 3;
    }
    .form__group:nth-of-type(7) {
      grid-area: 5 / 1 / 6 / 3;
    }
  }

  &__password-btn {
    justify-content: space-between;
    border-color: $form-color-border;
  }
}
