$block: '.order-resume';
$block2: '.items-table';

$order-resume-vertical-gap: $page-container-gap;

#{$block} {
  width: 100%;
  height: 100%;

  &__container {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  &__heading {
    width: 100%;
  }

  &__title {
    font-weight: 700;
    color: var(--color-neutral-40);
  }

  &__table {
    display: flex;
    flex-direction: column;
    gap: $order-resume-vertical-gap;
    width: 100%;
  }
}

#{$block2} {
  $blockanme: &;
  display: flex;
  flex-direction: column;
  gap: rem(16px);
  width: 100%;

  &__list {
    display: flex;
    flex-direction: column;
    gap: $order-resume-vertical-gap;
    width: 100%;
    padding: 0;
    margin: 0;
    list-style: none;
  }

  &__item {
    display: grid;
    grid-template-columns: minmax(0, 2fr) minmax(0, 32px) minmax(0, 100px);
    gap: rem(12px);
    padding: rem(12px);
    padding-top: 0;
    padding-bottom: 0;
    background-color: var(--color-neutral-100);
    border-radius: 6px;
  }

  &__left {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: rem(12px);

    @include breakpoint-max(xs) {
      flex-direction: column;
      align-items: flex-start;
    }
  }

  &__aspect-ratio {
    position: relative;
    display: flex;
    flex-shrink: 0;
    width: rem(64px);

    @include breakpoint-max(xl) {
      width: rem(48px);
    }

    &:after {
      content: '';
      display: block;
      padding-bottom: calc(100% / 1 * 1);
    }
  }

  &__img {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center;
    border-radius: 4px;
    background-color: var(--color-neutral-90);

    &--icon {
      width: 32px;
      height: 32px;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);
      color: var(--color-neutral-60);
    }
  }

  &__title-desc-wrapper {
    display: flex;
    flex-direction: column;
    gap: rem(2px);
  }

  &__text {
    font-size: rem(16px);
    line-height: 1.3;
    font-weight: 500;
    color: var(--color-neutral-50);

    @include breakpoint-max(xl) {
      font-size: rem(12px);
      letter-spacing: 0.02em;
    }

    &--emphasys {
      color: var(--color-neutral-40);
    }

    &--color-primary {
      color: #5bd5d9;
    }

    &--desc {
      overflow: hidden;
      font-size: rem(12px);
      font-weight: 400;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }

  &__middle {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__right {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-right: rem(8px);
  }

  &--header {
    #{$block2}__item {
      background-color: unset;
    }
  }

  &--bottom {
    #{$block2} {
      &__item {
        position: relative;
        background-color: unset;
        padding-top: $order-resume-vertical-gap;

        &:after {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          transform: translateY(-50%);
          width: 100%;
          height: 2px;
          background-color: var(--color-neutral-50);
        }
      }

      &__right {
        padding-right: 0;
      }
    }
  }
}
