$block: '.selection-review';

#{$block} {
  &__heading-wrapper {
    display: block;
    width: 100%;
  }

  &__wrapper {
    @include breakpoint-max(md) {
      flex-direction: column-reverse;
    }
  }

  &__image-grid-wrapper {
    display: block;
    width: 100%;
  }

  &__resume-wrapper {
    display: flex;
    flex-shrink: 0;
    max-width: rem(432px);
    width: 100%;

    @include breakpoint-max(lg) {
      max-width: rem(384px);
    }

    @include breakpoint-max(md) {
      max-width: 100%;
    }
  }

  &__resume-container {
    overflow: hidden;
    width: 100%;
    padding: rem(40px);
    background-color: var(--color-neutral-100);
    border-radius: $br;
  }
}
