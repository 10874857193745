$block: '.modal';
$block2: '.modal-header';

#{$block} {
  position: fixed;
  z-index: $zindex-dialog;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  padding-top: $modal-pd-vertical;
  padding-bottom: $modal-pd-vertical;
  padding-left: $modal-pd-horizontal;
  padding-right: $modal-pd-horizontal;
  
  @include breakpoint-max(xxl) {
    padding-left: $modal-pd-horizontal--xxl;
    padding-right: $modal-pd-horizontal--xxl;
  }
  
  @include breakpoint-max(xl) {
    padding-left: $modal-pd-horizontal--xl;
    padding-right: $modal-pd-horizontal--xl;
  }
  
  @include breakpoint-max(lg) {
    padding-left: $modal-pd-horizontal--lg;
    padding-right: $modal-pd-horizontal--lg;
  }
  
  @include breakpoint-max(sm) {
    padding-left: $modal-pd-horizontal--sm;
    padding-right: $modal-pd-horizontal--sm;
  }

  &__backdrop {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: $modal-backdrop-color;
    opacity: $modal-backdrop-opacity;
  }

  &__wrapper {
    overflow: hidden;
    position: relative;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    width: 100%;
    max-width: $modal-wrapper-max-width;
    max-height: $modal-wrapper-max-height;
    padding: 0;
    color: var(--color-neutral-10);
    border-radius: $br--xlarge;
    background-color: var(--color-neutral-100);
    box-shadow: $bs--large;

    @include breakpoint-max(xxl) {

    }
  }

  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: $modal-icon-size !important;
    height: $modal-icon-size !important;
    color: var(--color-neutral-20) !important;

    @include breakpoint-max(xxl) {
      width: $modal-icon-size--xxl !important;
      height: $modal-icon-size--xxl !important;
    }

    @include breakpoint-max(sm) {
      width: $modal-icon-size--sm !important;
      height: $modal-icon-size--sm !important;
    }
  }

  &__body {
    position: relative;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: $page-wrapper-gap;
    width: 100%;
    height: 100%;
    padding: $modal-body-pd-vertical $modal-body-pd-horizontal;

    @include breakpoint-max(xxl) {
      padding: $modal-body-pd-vertical--xxl $modal-body-pd-horizontal--xxl;
    }

    @include breakpoint-max(sm) {
      padding: $modal-body-pd-vertical--sm $modal-body-pd-horizontal--sm;
    }
  }

  &__btn-group {
    display: flex;
    justify-content: space-between;
    gap: rem(12px);
    width: 100%;

    &--column {
      flex-direction: column-reverse;

      #{$block} {
        &__btn {
          width: 100%;
        }
      }
    }
  }

  &__btn {
    &--hide {
      visibility: hidden;
      pointer-events: none;
      min-width: 0;
      padding: 0;
      border: none;
    }
  }

  &__footer {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: rem(30px);
    height: auto;
    width: 100%;
    padding: rem(15px) $modal-body-pd-horizontal $modal-body-pd-vertical;

    @include breakpoint-max(xxl) {
      padding: rem(15px) $modal-body-pd-horizontal--xxl $modal-body-pd-vertical--xxl;
    }

    @include breakpoint-max(sm) {
      padding: rem(15px) $modal-body-pd-horizontal--sm $modal-body-pd-vertical--sm;
    }

    &--border {
      border-top: 1px solid var(--color-neutral-90);
    }
  }

  &__footer-actions {
    display: flex;
    align-items: center;
    gap: rem(24px);
  }

  &--sm {
    #{$block} {
      &__wrapper {
        max-width: rem(560px);
      }
    }
  }
}

#{$block2} {
  position: relative;
  height: auto;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: $modal-body-pd-vertical $modal-body-pd-horizontal;
  padding-bottom: 0;
  
  @include breakpoint-max(xxl) {
    padding: $modal-body-pd-vertical--xxl $modal-body-pd-horizontal--xxl;
    padding-bottom: 0;
  }
  
  @include breakpoint-max(sm) {
    padding: $modal-body-pd-vertical--sm $modal-body-pd-horizontal--sm;
    padding-bottom: 0;
  }

  &__container {
    display: flex;
    align-items: center;
    gap: $page-wrapper-gap;

    &--left {
      gap: rem(8px);
    }
    &--right {
      gap: rem(8px);
    }
  }

  &__btn-close {
    width: rem(40px);
    max-width: rem(40px);
    min-width: rem(40px);
    height: rem(40px);
    max-height: rem(40px);
    min-height: rem(40px);
    padding: 0 !important;
  }

  &__icon-wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: $modal-icon-size !important;
    height: $modal-icon-size !important;
    color: var(--color-neutral-20) !important;
    
    @include breakpoint-max(xxl) {
      width: $modal-icon-size--xxl !important;
      height: $modal-icon-size--xxl !important;
    }
    
    @include breakpoint-max(sm) {
      width: $modal-icon-size--sm !important;
      height: $modal-icon-size--sm !important;
    }
  }

  &__icon {
    width: calc($modal-icon-size - rem(8px)) !important;
    height: calc($modal-icon-size - rem(8px)) !important;
    color: inherit !important;
    
    @include breakpoint-max(xxl) {
      width: calc($modal-icon-size--xxl - rem(8px)) !important;
      height: calc($modal-icon-size--xxl - rem(8px)) !important;
    }
    
    @include breakpoint-max(sm) {
      width: calc($modal-icon-size--sm) !important;
      height: calc($modal-icon-size--sm) !important;
    }
  }

  &__title {
    font-size: rem(24px);
    line-height: 1.1;
    font-weight: 600;
    color: var(--color-neutral-20);
    margin-bottom: 0;
  }

  &--justify-center {
    #{$block2} {
      &__container {
        justify-content: center;
        &--left {
          width: 100%;
          padding-left: 40px;
        }
      }
    }
  }
}