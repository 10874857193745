$block: '.image-thumb';

#{$block} {
  // start: abstracts
  $thumb-outer-padding: rem(12px);
  $thumb-icon-size: rem(24px);
  $thumb-outer-padding--md: rem(10px);
  $thumb-icon-size--md: rem(24px);
  // end: abstracts
  display: block;
  width: 100%;
  height: 100%;

  &__inner {
    overflow: hidden;
    position: relative;
    width: 100%;
    height: 100%;
    border-radius: $br;
    border: 1px solid var(--color-neutral-80);
  }

  &__overlay {
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    &:after {
      --color-opacity: 0.5;

      content: '';
      display: block;
      position: absolute;
      z-index: 2;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: $br;
      background-color: var(--color-neutral-100);
      opacity: 0;
      transition: all 200ms ease;

      @include breakpoint-max(md) {
        opacity: 0 !important;
      }
    }
  }

  &__btn-group {
    position: absolute;
    z-index: 3;
    right: 0;
    top: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    width: fit-content;
    padding: rem(6px);

    @include breakpoint-max(md) {
      padding: rem(6px);
    }
  }

  &__btn {
    overflow: hidden;
    position: relative;
    z-index: 3;
    display: flex;
    align-items: center;
    justify-content: center;
    width: fit-content;
    height: fit-content;
    padding: $thumb-outer-padding;
    color: var(--color-neutral-10);
    border: 1px solid transparent;
    border-radius: $br--small;
    opacity: 0;
    transition: all 200ms ease;

    &:after {
      --color-opacity: .4;

      content: '';
      position: absolute;
      z-index: -1;
      width: 100%;
      height: 100%;
      background-color: var(--color-neutral-100);
      transition: all 200ms ease;
    }

    &:not(:disabled) {
      @include breakpoint-min(md) {
        &:hover {
          color: var(--color-primary-40);

          &:after {
            --color-opacity: .8;
          }
        }
      }

      @include breakpoint-max(md) {
        padding: $thumb-outer-padding--md;
        opacity: 1;

        &:after {
          --color-opacity: 0.7;

          content: '';
          position: absolute;
          z-index: -1;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          border-radius: $br--small;
          background-color: var(--color-neutral-80);
        }
      }
    }
  }

  &__icon {
    height: $thumb-icon-size;
    width: $thumb-icon-size;

    @include breakpoint-max(md) {
      height: $thumb-icon-size--md;
      width: $thumb-icon-size--md;
    }
  }

  &__comments-counter {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: rem(20px);
    height: rem(20px);
    font-size: rem(14px);
    font-weight: 500;
    color: var(--color-neutral-100);
    background-color: var(--color-primary-40);
    border-radius: rem(4px);
  }

  &__image {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  &__img {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }

  @include breakpoint-min(md) {
    &:hover {
      #{$block} {
        &__btn {
          opacity: 1;
          pointer-events: all;
        }

        // &__overlay {
        //   &:after {
        //     --color-opacity: 0.3!important;
        //     opacity: 1;
        //   }
        // }
      }
    }
  }

  &--is-select {
    #{$block} {
      &__overlay {
        &:after {
          border: 0px solid var(--color-neutral-10);
          opacity: 1;
        }
      }

      &__btn-group {
        animation: fadeOutIn .5s;
      }

      &__btn {
        &--select {
          color: var(--color-neutral-10);
          opacity: 1;
          pointer-events: all;
          
          @include breakpoint-max(md) {            
            color: var(--color-neutral-100);

            &:after {
              --color-opacity: 1;
              
              content: '';
              position: absolute;
              z-index: -1;
              left: 0;
              top: 0;
              width: 100%;
              height: 100%;
              border-radius: $br--small;
              background-color: var(--color-primary-40);
            }
          }
        }

        &--hidden {
          opacity: 1;
          pointer-events: none;
        }

        &:nth-child(1) {
          order: 0;
        }

        &:nth-child(2) {
          order: 2;
        }

        &:nth-child(3) {
          order: 1;
        }
      }
    }
  }

  &--has-comments {
    #{$block} {

      &__btn {
        &--comments {
          color: var(--color-neutral-10);
          opacity: 1;
          pointer-events: all;
          
          @include breakpoint-max(md) {            
            color: var(--color-neutral-100);

            &:after {
              --color-opacity: 1;
              
              content: '';
              position: absolute;
              z-index: -1;
              left: 0;
              top: 0;
              width: 100%;
              height: 100%;
              border-radius: $br--small;
              background-color: var(--color-primary-40);
            }
          }
        }
      }
    }
  }

  &--cursor-pointer {
    cursor: pointer;
  }

  &--select-hidden {
    #{$block} {
      &__btn {
        &--select {
          background-color: unset;
          cursor: default;

          &:after {
            background-color: unset;
          }
        }
      }
    }

  }
}

@keyframes fadeOutIn {
  0% {
    opacity: 0;
  }
  20% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
