$block: '.socialbar';

#{$block} {
  display: flex;
  flex-shrink: 0;
  width: 100%;

  &__list {
    display: flex;
    justify-content: space-between;
    justify-content: center;
    gap: rem(8px);
    width: 100%;
  }

  &__item {
    display: block;

    &--hidden {
      position: absolute;
      z-index: $zindex-hidden;
      max-height: 0;
      max-width: 0;
      padding: 0;
      visibility: hidden;
      opacity: 0;
      pointer-events: none;
    }
  }

  &__wrapper {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: $btn-height;
    width: $btn-height;
    border-radius: $btn-border-radius;

    &::after {
      content: '';
      position: absolute;
      z-index: 0;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: inherit;
      background-color: var(--color-neutral-100);
      opacity: 0;
      transition: all 200ms ease;
    }

    @include breakpoint-min(md) {
      &:hover {
        &::after {
          opacity: 1;
        }

        #{$block} {
          &__icon {
            color: var(--color-neutral-10);
          }
        }
      }
    }
  }

  &__icon {
    position: relative;
    z-index: 1;
    width: $btn-icon-size;
    height: $btn-icon-size;
    color: var(--color-neutral-40);
    transition: all 200ms ease;
  }

  &--offcanvas {
    justify-content: space-between;
    width: max-content;

    #{$block} {
      &__icon {
        height: $btn-icon-size--large;
        width: $btn-icon-size--large;
        color: var(--color-neutral-30);
      }
    }
  }
}
