$block: '.approval-resume';

#{$block} {
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: $page-container-gap;
  width: 100%;
  padding: rem(16px);
  border-radius: $br;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  background-color: var(--color-neutral-100);

  @include breakpoint-max(xxl) {
    gap: rem(16px);
    padding: rem(12px);
  }

  &__title-status-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: rem(16px);
    width: 100%;
  }

  &__title {
    width: 100%;
    font-size: rem(20px);
    line-height: 1.2;
    font-weight: 600;
    color: var(--color-neutral-30);

    @include breakpoint-max(xxl) {
      font-size: rem(16px);
    }

    &--less-emphasys {
      font-size: rem(18px);
      line-height: 1;
      font-weight: 500;
      color: var(--color-neutral-40);

      @include breakpoint-max(xxl) {
        font-size: rem(16px);
      }
    }
  }

  &__status {
    display: flex;
    align-items: center;
    flex-shrink: 0;
    gap: rem(8px);
  }

  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: rem(20px);
    height: rem(20px);
  }

  &__text {
    font-size: rem(18px);
    line-height: 1.4;
    font-weight: 600;
    color: var(--color-neutral-40);

    @include breakpoint-max(xxl) {
      font-size: rem(14px);
      font-weight: bold;
    }

    &--less-emphasys {
      font-size: rem(14px);
      line-height: 1;
      font-weight: 500;
    }
  }

  &__infos-wrapper {
    display: flex;
    justify-content: space-between;
    gap: rem(32px);
    padding-left: rem(8px);
    padding-right: rem(8px);
  }

  &__info-group {
    display: flex;
    flex: 1;
    flex-direction: column;
    gap: rem(8px);

    @include breakpoint-max(xxl) {
      gap: rem(4px);
    }
  }

  &--pending {
    #{$block} {
      &__icon {
        color: var(--color-primary-60);
      }
    }
  }
  &--inprogress {
    #{$block} {
      &__icon {
        color: var(--color-primary-60);
      }
    }
  }
  &--expired {
    #{$block} {
      &__icon {
        color: var(--color-error-60);
      }
    }
  }
  &--finished {
    #{$block} {
      &__icon {
        color: var(--color-success-60);
      }
    }
  }
}
