$block: '.selection';
$block2: '.gallery';

#{$block},
#{$block2} {
  display: block;
  width: 100%;

  &:not(:last-of-type) {
    padding-bottom: rem(32px);
  }

  &__flex-container {
    display: flex;
    flex-direction: column;
    gap: $page-container-gap;
    width: 100%;
  }

  &__image-grid-wrapper {
    display: block;
    width: 100%;

    &--reduced-container {
      padding-left: rem(32px);
      padding-right: rem(32px);

      @include breakpoint-max(xxl) {
        padding-left: rem(48px);
        padding-right: rem(48px);
      }

      @include breakpoint-max(xl) {
        padding-left: rem(40px);
        padding-right: rem(40px);
      }

      @include breakpoint-max(lg) {
        padding-left: rem(96px);
        padding-right: rem(96px);
      }

      @include breakpoint-max(md) {
        padding-left: 0px;
        padding-right: 0px;
      }
    }
  }

  &__heading-wrapper {
    @extend #{$block}__image-grid-wrapper !optional;
  }

  &__project-banner-resume {
    @extend #{$block}__grid-wrapper !optional;
  }

  &__banner-resume {
    overflow: hidden;
    display: flex;
    width: 100%;
    border-radius: $br;
    background-color: var(--color-neutral-100);

    @include breakpoint-max(md) {
      flex-direction: column;
    }
  }

  &__banner {
    position: relative;
    width: 100%;

    &:after {
      content: '';
      display: block;
      padding-bottom: calc(100% / 2 * 1);
    }

    &--3-1 {
      &:after {
        padding-bottom: calc(100% / 5 * 2);
      }
    }
  }

  &__banner-img {
    position: absolute;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }

  &__resume {
    display: flex;
    flex-shrink: 0;
    width: rem(504px);
    height: auto;
    padding: rem(40px);
    
    @include breakpoint-max(xxl) {
      width: rem(550px);
      padding: rem(24px);
    }
    
    @include breakpoint-max(xl) {
      width: rem(400px);
    }
    
    @include breakpoint-max(sm) {
      width: 100%;
    }
  }

  &__tabs-actions-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: rem(32px);
    width: 100%;

    @include breakpoint-max(sm) {
      flex-direction: column;
      gap: rem(12px);
    }
  }

  &__actions {
    display: flex;
    align-items: center;
    flex-shrink: 0;
    gap: rem(16px);

    @include breakpoint-max(sm) {
      width: 100%;

      > button {
        width: 100%;
      }
    }
  }

  &__tabs-wrapper {
    @extend #{$block}__grid-wrapper !optional;
    width: 100%;
  }

  &--top {
  }

  &--bottom {
  }
}
