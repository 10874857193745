$block: '.home1';

#{$block} {
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  max-height: 100vh;

  &__container {
    overflow: hidden;
    position: relative;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    height: 100%;
    padding-left: 0!important;
    padding-right: 0!important;
  }

  &__bg {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 2;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
  }

  &__content {
    overflow: hidden;
    position: relative;
    z-index: 4;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    flex-grow: 1;
    gap: rem(40px);
    height: 100%;
    max-width: rem(304px);
    padding-left: rem(32px);
    padding-right: rem(32px);
    padding-top: 5vh;
    padding-bottom: 5vh;
    margin-left: rem(112px);
    
    @include breakpoint-min(xxxl) {
      justify-content: center;
      gap: rem(64px);
    }
    
    @include breakpoint-max(xl) {
      gap: $page-container-gap;
    }

    @include breakpoint-max(md) {
      flex-direction: row;
      gap: $page-container-gap;
      max-height: $header-height--md;
      max-width: unset;
      width: 100%;
      padding-top: 0;
      padding-bottom: 0;
      padding-left: $page-container-pd-horizontal--sm;
      padding-right: $page-container-pd-horizontal--sm;
      margin-left: 0;
    }

    &:after {
      content: '';
      position: absolute;
      z-index: -1;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-color: var(--color-neutral-100);
      opacity: 0.75;
    }
  }  

  &__logo-wrapper {
    display: flex;
    flex-shrink: 0;
    width: 100%;
  }
  
  &__project-title-wrapper {
    display: flex;
    flex-shrink: 0;
    width: 100%;
  }
  
  &__navbar-wrapper {
    overflow: hidden;
    display: flex;
    flex-shrink: 1;
    width: 100%;
  }
  
  &__socialbar-wrapper {
    display: flex;
    flex-shrink: 0;
    width: 100%;
  }
  
  &--banner2 {
    #{$block} {
      &__container {
      }
    
      &__bg {
      }
    
      &__content {
        overflow: hidden;
        position: absolute;
        z-index: 4;
        top: 0;
        left: 0;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        flex-grow: 1;
        gap: rem(40px);
        height: rem(96px);
        width: 100%;
        max-width: 100%;
        padding-left: rem(32px);
        padding-right: rem(32px);
        padding-top: rem(16px);
        padding-bottom: rem(16px);
        margin-left: 0;
        
        @include breakpoint-max(xl) {
        }
    
        @include breakpoint-min(xxxl) {
        }
    
        &:after {
          content: '';
          position: absolute;
          z-index: -1;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          background-color: var(--color-neutral-100);
          opacity: 0.75;
        }
      }  

      &__logo-wrapper {
        width: 100%;
      }
      
      &__project-title-wrapper {
        width: 100%;
      }
      
      &__navbar-wrapper {
        width: 100%;
      }
      
      &__socialbar-wrapper {
        width: 100%;
      }
    }
  }  
  &--banner3 {
    #{$block} {
      &__container {
      }
    
      &__bg {
      }
    
      &__content {
        overflow: hidden;
        position: absolute;
        z-index: 4;
        top: 0;
        left: 0;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        flex-grow: 1;
        gap: rem(40px);
        height: rem(96px);
        width: 100%;
        max-width: 100%;
        padding-left: rem(32px);
        padding-right: rem(32px);
        padding-top: rem(16px);
        padding-bottom: rem(16px);
        margin-left: 0;
        
        @include breakpoint-max(xl) {
        }
    
        @include breakpoint-min(xxxl) {
        }
    
        &:after {
          content: '';
          position: absolute;
          z-index: -1;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          background-color: var(--color-neutral-100);
          opacity: 0.75;
        }
      }  

      &__logo-wrapper {
        width: 100%;
      }
      
      &__project-title-wrapper {
        width: 100%;
      }
      
      &__navbar-wrapper {
        overflow: hidden;
        width: 100%;
      }
      
      &__socialbar-wrapper {
        width: 100%;
      }
    }
  }
}
