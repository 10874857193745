@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700;900&display=swap');
$font-primary: 'Montserrat', sans-serif;
$font-secondary: 'Montserrat', sans-serif;

$max-font-size: 16px; // $breakpoints-xl; //it will set the breakpoint that the font will reach the MAXIMUM size
$min-font-size: 14px; // $breakpoints-xl; //it will set the breakpoint that the font will reach the MAXIMUM size

// IT WILL GENERATE THE BASER FONT SIZE TO BE USED ON HTML (REM)
$max-width: 1400px; // $breakpoints-xl; //it will set the breakpoint that the font will reach the MAXIMUM size
$min-width: 576px; // $breakpoints-xs; //it will set the breakpoint that the font will reach the MINIMUM size
$max-font: 16px; // it may need to be adjusted based on the font-family
$min-font: 14px; // it may need to be adjusted based on the font-family
