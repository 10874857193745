$block: '.not-found';

#{$block} {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: rem(48px);
  height: 100%;
  width: 100%;

  &__section {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: rem(32px);
  }

  &__container {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: $page-container-gap;
  }
}
