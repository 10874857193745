$block: '.header';

#{$block} {
  position: relative;
  z-index: $zindex-header;
  width: 100%;
  height: $header-height;
  font-family: $header-font-family;

  * {
    font-family: inherit;
  }

  @include breakpoint-max(xl) {
    height: $header-height--xl;
  }

  @include breakpoint-max(md) {
    height: $header-height--md;
  }

  &__inner {
    position: relative;
    width: 100%;
    height: inherit;
    box-shadow: $bs-header;

    &--sticky {
      position: fixed;
      left: 0;
      top: 0;
      height: $header-height--sticky;
      transform: translateY(-100%);
      background-color: hsl(0deg 0% 100% / 98%);
  
      @include breakpoint-max(md) {
        height: $header-height--sticky--md;
      }
    }

    &--animate {
      transform: translateY(0);
      transition: all 700ms, height 0ms;
    }
  }

  &__container {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    gap: $header-container-gap;
    height: inherit;
    
    @include breakpoint-max(xl) {
      gap: $header-container-gap--xl;
    }
    
    @include breakpoint-max(md) {
      gap: $header-container-gap--md;
    }
  }

  &__mobile-trigger {
    overflow: hidden;
    position: absolute;
    top: 0;
    right: 0;
    width: fit-content;
    max-width: 0;
    max-height: 0;
    transition: all 0.5s ease;

    @include breakpoint-max(md) {
      overflow: visible;
      display: flex;
      align-items: center;
      justify-content: center;
      max-height: 100%;
      max-width: 100%;
      width: fit-content;
      height: $header-height--md;
    }
  }

  &__left {
    position: relative;
    display: flex;
    align-items: center;
    gap: rem(40px);
    height: inherit;
    opacity: 1;
    
    @include breakpoint-max(md) {
      position: absolute;
      left: 0;
      top: 50%;
      transform: translate(0%, -50%);
      gap: inherit;
      width: calc(100% - $header-hamburguer-menu-size - $header-container-gap--md);
    }
  }

  &__logo {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    height: auto;

    &--mobile {
      overflow: hidden;
      position: absolute;
      z-index: -999;
      height: 0;
      max-height: 0;
      max-width: 0;

      @include breakpoint-max(md) {
        flex-shrink: 0;
        overflow: visible;
        position: relative;
        z-index: initial;
        max-height: 100%;
        max-width: 100%;
        height: 45px;
      }
    }
  }

  &__logo-link {
    display: block;
    height: inherit;
  }

  &__logo-img {
    display: block;
    height: inherit;
    width: auto;
  }

  &__project-title-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: fit-content;
    height: 100%;

    @include breakpoint-max(md) {
      width: 100%;
    }
  }

  &__middle {
    position: relative;
    display: flex;
    align-items: center;
    gap: $header-container-gap;
    height: inherit;

    @include breakpoint-max(md) {
      position: absolute;
      left: 0;
      flex-direction: column;
      align-items: flex-start;
      flex-shrink: 1;
      gap: $header-menu-spacing--md;
      height: 100vh;
      width: $header-menu-width--md;
      padding: $header-menu-spacing--md;
      background-color: var(--color-neutral-95);
      box-shadow: $bs-header;
      opacity: 0;
      transform: translateX(-101%);
      transition: opacity 0.3s ease, transform 0.5s ease;

      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 100%;
        height: 100%;
        width: calc(100vw - $header-menu-width--md);
        background-color: var(--color-primary-40);
        opacity: 0;
        transform: translateX(-101%);
        transition: opacity 0.3s ease, transform 0.5s ease;
      }
    }
  }

  &__menu {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: fit-content;
    height: inherit;

    @include breakpoint-max(md) {
      width: 100%;
      margin-bottom: $header-medias-height--md + $header-menu-spacing--md;
      overflow-y: auto;
    }
  }

  &__right {
    position: relative;
    display: flex;
    align-items: center;
    gap: rem(32px);
    height: inherit;
    
    @include breakpoint-max(xxl) {
      
    }

    @include breakpoint-max(md) {
      position: absolute;
      left: $header-menu-spacing--md;
      top: calc(100vh - $header-medias-total-height--md);
      gap: inherit;
      width: calc($header-menu-width--md - ($header-menu-spacing--md * 2));
      height: $header-medias-height--md + $header-menu-spacing--md;
      padding-top: $header-menu-spacing--md;
      opacity: 0;
      transform: translateX(-100%);
      transition: all 0.3s ease !important;

      .medias__link {
        background-color: var(--color-neutral-95);
        height: 40px !important;
        width: 40px !important;
      }

      .medias__img {
        height: 20px !important;
        width: 20px !important;
      }
    }
  }

  &__user-btn {
    padding-right: 0!important;
    padding-left: 0!important;
      
    > span {
      overflow: hidden;
      max-width: rem(120px);
      font-size: inherit;
      font-weight: inherit;
      line-height: inherit;
      letter-spacing: inherit;
      color: inherit;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-weight: 400;
    }
  }
}

.body--mobile-menu-active {
  #{$block} {
    &__middle {
      opacity: 1;
      transform: translate(0);

      &:before {
        opacity: 0.8;
        transform: translate(0);
      }
    }

    &__right {
      opacity: 1;
      transform: translate(0);
    }
  }
}

.html--mobile-menu-active {
  overflow: hidden;

  #{$block} {
    &__mobile-trigger {
      position: relative;
      z-index: 1;
      left: 240px;
    }
  }
}
