$block: '.album-approval';

#{$block} {
  $right-content-width: rem(340px);
  $controls-container-height: rem(64px);
  $controls-container-height--xxl: auto;

  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 100%;
  height: calc(100vh - $header-height);

  @include breakpoint-max(md) {
    height: 100%;
  }

  &__container {
    overflow: hidden;
    display: flex !important;
    flex-direction: column;
    flex-grow: 1;
    width: 100%;
    height: 100%;
  }

  &__flex-container {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    height: 100%;
    gap: $page-container-gap;
    width: 100%;
  }

  &__heading-wrapper {
    display: flex;
    flex-shrink: 0;
    width: 100%;
  }

  &__content {
    overflow: hidden;
    display: flex;
    flex-grow: 1;
    width: 100%;
    height: 100%;

    @include breakpoint-max(xxl) {
      height: calc(100vh - ($page-section-pd-vertical--large * 8));
    }
  }

  &__column {
    overflow: hidden;
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;

    &--resume-chat-wrapper {
      z-index: 2;
      flex-shrink: 0;
      gap: rem(16px);
      width: $right-content-width;
    }
  }

  &__row {
    overflow: hidden;
    display: flex;

    &--grow-vertical {
      flex-grow: 1;
      height: 100%;
    }

    &--bg-100 {
      overflow: hidden;
      position: relative;
      display: flex;
      flex-shrink: 0;
      width: 100%;
      background-color: var(--color-neutral-100);
      border-radius: $br;
      border-top-left-radius: 0;
    }
  }

  &__iframe-wrapper {
    overflow: hidden;
    position: relative;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    border-top-left-radius: $br;
  }

  &__iframe-overlay {
    position: absolute;
    z-index: 3;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    padding: rem(40px);
    font-size: rem(20px);
    font-weight: 500;
    color: var(--color-neutral-100);
    opacity: 0;
    cursor: pointer;
    transition: opacity .2s ease;
    
    &:after {
      content: "";
      position: absolute;
      z-index: -1;
      width: 100%;
      height: 100%;
      background-color: var(--color-neutral-10);
      opacity: 0;
      transition: opacity .2s ease;
    }

    @include breakpoint-min(md) {
      &:hover {
        opacity: 1;

        &:after {
          opacity: .5;
        }
      }
    }

    &--hidden {
      opacity: 0;
      visibility: hidden;
    }
  }

  &__iframe {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    outline: none;
  }

  &__resume-wrapper {
    width: 100%;
  }

  &__chat-wrapper {
    overflow: hidden;
    display: flex;
    flex-grow: 1;
    width: 100%;
    padding-bottom: rem(16px);
  }

  &__iframe-controls {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: rem(16px);
    height: $controls-container-height;
    width: 100%;
    background-color: var(--color-neutral-100);

    @include breakpoint-max(xxl) {
      height: $controls-container-height--xxl;
    }
  }

  &__arrow-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    transition: all 0.2s;

    &--left {
    }

    &--right {
    }

    &:disabled {
      opacity: 0;
      pointer-events: none;
    }

    @include breakpoint-min(md) {
      &:hover {
        #{$block} {
          &__arrow-icon {
            color: var(--color-primary-40);
          }
        }
      }
    }
  }

  &__arrow-icon {
    height: rem(40px);
    width: rem(40px);
    color: var(--color-neutral-40);
    transition: all 0.2s ease;

    @include breakpoint-max(xxl) {
      height: rem(32px);
      width: rem(32px);
    }
  }

  &__pages-count {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    height: 100%;
  }

  &__pages-span {
    font-size: rem(20px);
    line-height: 1;
    font-weight: 500;
    white-space: nowrap;

    @include breakpoint-max(xxl) {
      font-size: rem(18px);
    }
  }

  &__finish-btn-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    width: $right-content-width;
  }

  &__finish-btn {
    height: 100%;
  }
}
