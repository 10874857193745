$block: '.payment-status';

#{$block} {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: rem(10px);
  width: 100%;
  padding: rem(8px) rem(16px);
  background-color: var(--color-neutral-80);
  font-weight: 600;
  letter-spacing: 0.02em;
  color: var(--color-neutral-40);
  border-radius: 99999px;

  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    height: rem(16px);
    width: rem(16px);
    color: inherit;
  }

  &__text {
    font-size: rem(12px);
    line-height: rem(16px);
    font-weight: inherit;
    color: inherit;
    letter-spacing: inherit;
  }

  &--denied {
    background-color: var(--color-error-90);
    color: var(--color-error-40);
  }
  &--redeem {
    background-color: var(--color-success-90);
    color: var(--color-success-40);
  }
  &--pending {
    background-color: var(--color-neutral-80);
    color: var(--color-neutral-40);
  }
  &--processing {
    background-color: var(--color-primary-90);
    color: var(--color-primary-40);
  }
}
