$block: '.btn';

#{$block} {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: $btn-icon-gap;
  min-height: $btn-height;
  padding-top: $btn-padding-top;
  padding-bottom: $btn-padding-bottom;
  padding-left: $btn-padding-left;
  padding-right: $btn-padding-right;
  font-family: $btn-font-family;
  font-size: $btn-font-size;
  line-height: $btn-line-height;
  letter-spacing: $btn-letter-spacing;
  font-weight: $btn-font-weight;
  color: $btn-color-font;
  border-style: solid;
  border-width: $btn-border-width;
  border-radius: $btn-border-radius;
  border-color: $btn-color-border;
  outline-style: none;
  outline-offset: $btn-ring-offset;
  outline-width: $btn-ring-width;
  outline-color: $btn-color-ring;
  background-color: $btn-color-bg;
  transition: $btn-transition;
  cursor: pointer;

  svg {
    color: inherit;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: $btn-icon-size;
    min-width: $btn-icon-size;
    height: $btn-icon-size;
    min-height: $btn-icon-size;
  }

  

  &__loader-wrapper {
    position: absolute;
    z-index: 3;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    border-radius: inherit;
    background-color: $btn-color-bg__active;
  }

  &__loader { 
    position: absolute;
    z-index: 4;
    width: rem(50px);
    height: rem(50px);
    animation: rotate 2s linear infinite;
  }
  
  &__loader-path {
    stroke: $btn-color-font__active;
    stroke-linecap: round;
    animation: dash 1.5s ease-in-out infinite;
  }

  &--icon {
    padding-right: $btn-icon-padding-right;
    padding-left: $btn-icon-padding-left;
  }

  &--large {
    gap: $btn-icon-gap--large;
    min-height: $btn-height--large;
    padding-top: $btn-padding-top--large;
    padding-bottom: $btn-padding-bottom--large;
    padding-left: $btn-padding-left--large;
    padding-right: $btn-padding-right--large;
    font-size: $btn-font-size--large;
    line-height: $btn-line-height--large;
    letter-spacing: $btn-letter-spacing--large;
    font-weight: $btn-font-weight--large;
    border-width: $btn-border-width--large;
    border-radius: $btn-border-radius--large;
    outline-offset: $btn-ring-offset--large;
    outline-width: $btn-ring-width--large;

    svg {
      width: $btn-icon-size--large;
      min-width: $btn-icon-size--large;
      height: $btn-icon-size--large;
      min-height: $btn-icon-size--large;
    }

    &#{$block} {
      &--icon {
        padding-right: $btn-icon-padding-right--large;
        padding-left: $btn-icon-padding-left--large;
      }
    }
  }

  &--small {
    gap: $btn-icon-gap--small;
    min-height: $btn-height--small;
    padding-top: $btn-padding-top--small;
    padding-bottom: $btn-padding-bottom--small;
    padding-left: $btn-padding-left--small;
    padding-right: $btn-padding-right--small;
    font-size: $btn-font-size--small;
    line-height: $btn-line-height--small;
    letter-spacing: $btn-letter-spacing--small;
    font-weight: $btn-font-weight--small;
    border-width: $btn-border-width--small;
    border-radius: $btn-border-radius--small;
    outline-offset: $btn-ring-offset--small;
    outline-width: $btn-ring-width--small;

    svg {
      width: $btn-icon-size--small;
      min-width: $btn-icon-size--small;
      height: $btn-icon-size--small;
      min-height: $btn-icon-size--small;
    }

    &#{$block} {
      &--icon {
        padding-right: $btn-icon-padding-right--small;
        padding-left: $btn-icon-padding-left--small;
      }
    }
  }

  @include breakpoint-min(md) {
    &--hover,
    &:hover {
      color: $btn-color-font__hover;
      border-color: $btn-color-border__hover;
      background-color: $btn-color-bg__hover;
    }
  }

  &--focus,
  &:focus {
    outline-style: solid;
  }

  &--active,
  &:active {
    color: $btn-color-font__active;
    border-color: $btn-color-border__active;
    background-color: $btn-color-bg__active;
  }

  &--outline {
    color: $btn-color-font--outline;
    border-color: $btn-color-border--outline;
    outline-color: $btn-color-ring--outline;
    background-color: $btn-color-bg--outline;

    @include breakpoint-min(md) {
      &--hover,
      &:hover {
        color: $btn-color-font--outline__hover;
        border-color: $btn-color-border--outline__hover;
        background-color: $btn-color-bg--outline__hover;
      }
    }

    &#{$block} {
      &--active,
      &:active {
        color: $btn-color-font--outline__active;
        border-color: $btn-color-border--outline__active;
        background-color: $btn-color-bg--outline__active;
      }

      &--disabled,
      &:disabled {
        color: $btn-color-font--outline__disabled;
        border-color: $btn-color-border--outline__disabled;
        background-color: $btn-color-bg--outline__disabled;
      }
    }

    #{$block} {
      &__loader-wrapper {
        background-color: $btn-color-bg--outline__active;
      }
      
      &__loader-path {
        stroke: $btn-color-font--outline__active;
      }
    }
  }

  &--primary {
    color: $btn-color-font--primary;
    border-color: $btn-color-border--primary;
    outline-color: $btn-color-ring--primary;
    background-color: $btn-color-bg--primary;

    @include breakpoint-min(md) {
      &--hover,
      &:hover {
        color: $btn-color-font--primary__hover;
        border-color: $btn-color-border--primary__hover;
        background-color: $btn-color-bg--primary__hover;
      }
    }
    
    #{$block} {
      &__loader-wrapper {
        background-color: $btn-color-bg--primary__active;
      }
      
      &__loader-path {
        stroke: $btn-color-font--primary__active;
      }
    }

    &#{$block} {
      &--active,
      &:active {
        color: $btn-color-font--primary__active;
        border-color: $btn-color-border--primary__active;
        background-color: $btn-color-bg--primary__active;
      }

      &--disabled,
      &:disabled {
        color: $btn-color-font--primary__disabled;
        border-color: $btn-color-border--primary__disabled;
        background-color: $btn-color-bg--primary__disabled;
      }

      &--outline {
        color: $btn-color-font--outline--primary;
        border-color: $btn-color-border--outline--primary;
        outline-color: $btn-color-ring--outline--primary;
        background-color: $btn-color-bg--outline--primary;

        @include breakpoint-min(md) {
          &--hover,
          &:hover {
            color: $btn-color-font--outline--primary__hover;
            border-color: $btn-color-border--outline--primary__hover;
            background-color: $btn-color-bg--outline--primary__hover;
          }
        }

        &#{$block} {
          &--active,
          &:active {
            color: $btn-color-font--outline--primary__active;
            border-color: $btn-color-border--outline--primary__active;
            background-color: $btn-color-bg--outline--primary__active;
          }

          &--disabled,
          &:disabled {
            color: $btn-color-font--outline--primary__disabled;
            border-color: $btn-color-border--outline--primary__disabled;
            background-color: $btn-color-bg--outline--primary__disabled;
          }
        }

        #{$block} {
          &__loader-wrapper {
            background-color: $btn-color-bg--outline--primary__active;
          }
          
          &__loader-path {
            stroke: $btn-color-font--outline--primary__active;
          }
        }
      }
    }
  }

  &--error {
    color: $btn-color-font--error;
    border-color: $btn-color-border--error;
    outline-color: $btn-color-ring--error;
    background-color: $btn-color-bg--error;

    @include breakpoint-min(md) {
      &--hover,
      &:hover {
        color: $btn-color-font--error__hover;
        border-color: $btn-color-border--error__hover;
        background-color: $btn-color-bg--error__hover;
      }
    }

    #{$block} {
      &__loader-wrapper {
        background-color: $btn-color-bg--error__active;
      }
      
      &__loader-path {
        stroke: $btn-color-font--error__active;
      }
    }

    &#{$block} {
      &--active,
      &:active {
        color: $btn-color-font--error__active;
        border-color: $btn-color-border--error__active;
        background-color: $btn-color-bg--error__active;
      }

      &--disabled,
      &:disabled {
        color: $btn-color-font--error__disabled;
        border-color: $btn-color-border--error__disabled;
        background-color: $btn-color-bg--error__disabled;
      }

      &--outline {
        color: $btn-color-font--outline--error;
        border-color: $btn-color-border--outline--error;
        outline-color: $btn-color-ring--outline--error;
        background-color: $btn-color-bg--outline--error;

        @include breakpoint-min(md) {
          &--hover,
          &:hover {
            color: $btn-color-font--outline--error__hover;
            border-color: $btn-color-border--outline--error__hover;
            background-color: $btn-color-bg--outline--error__hover;
          }
        }

        &#{$block} {
          &--active,
          &:active {
            color: $btn-color-font--outline--error__active;
            border-color: $btn-color-border--outline--error__active;
            background-color: $btn-color-bg--outline--error__active;
          }

          &--disabled,
          &:disabled {
            color: $btn-color-font--outline--error__disabled;
            border-color: $btn-color-border--outline--error__disabled;
            background-color: $btn-color-bg--outline--error__disabled;
          }
        }

        #{$block} {
          &__loader-wrapper {
            background-color: $btn-color-bg--outline--error__active;
          }
          
          &__loader-path {
            stroke: $btn-color-font--outline--error__active;
          }
        }
      }
    }
  }

  &--no-bg {
    color: $btn-color-font--no-bg;
    border-color: $btn-color-border--no-bg;
    outline-color: $btn-color-ring--no-bg;
    background-color: $btn-color-bg--no-bg;

    @include breakpoint-min(md) {
      &--hover,
      &:hover {
        color: $btn-color-font--no-bg__hover;
        border-color: $btn-color-border--no-bg__hover;
        background-color: $btn-color-bg--no-bg__hover;
      }
    }

    #{$block} {
      &__loader-wrapper {
        background-color: $btn-color-bg--no-bg__active;
      }
      
      &__loader-path {
        stroke: $btn-color-font--no-bg__active;
      }
    }

    &#{$block} {
      &--active,
      &:active {
        color: $btn-color-font--no-bg__active;
        border-color: $btn-color-border--no-bg__active;
        background-color: $btn-color-bg--no-bg__active;
      }

      &--disabled,
      &:disabled {
        color: $btn-color-font--no-bg__disabled;
        border-color: $btn-color-border--no-bg__disabled;
        background-color: $btn-color-bg--no-bg__disabled;
      }

      &--outline {
        color: $btn-color-font--outline--no-bg;
        border-color: $btn-color-border--outline--no-bg;
        outline-color: $btn-color-ring--outline--no-bg;
        background-color: $btn-color-bg--outline--no-bg;

        @include breakpoint-min(md) {
          &--hover,
          &:hover {
            color: $btn-color-font--outline--no-bg__hover;
            border-color: $btn-color-border--outline--no-bg__hover;
            background-color: $btn-color-bg--outline--no-bg__hover;
          }
        }

        &#{$block} {
          &--active,
          &:active {
            color: $btn-color-font--outline--no-bg__active;
            border-color: $btn-color-border--outline--no-bg__active;
            background-color: $btn-color-bg--outline--no-bg__active;
          }

          &--disabled,
          &:disabled {
            color: $btn-color-font--outline--no-bg__disabled;
            border-color: $btn-color-border--outline--no-bg__disabled;
            background-color: $btn-color-bg--outline--no-bg__disabled;
          }
        }

        #{$block} {
          &__loader-wrapper {
            background-color: $btn-color-bg--outline--no-bg__active;
          }
          
          &__loader-path {
            stroke: $btn-color-font--outline--no-bg__active;
          }
        }
      }
    }
  }

  &--disabled,
  &:disabled {
    outline-style: none !important;
    pointer-events: none !important;
  }
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes dash {
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -124;
  }
}
