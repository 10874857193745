$block: '.card-album';

#{$block} {
  overflow: hidden;
  position: relative;
  width: 100%;
  border: 1px solid var(--color-neutral-80);
  border-radius: $br;
  background-color: var(--color-neutral-100);

  &__inner {
    display: flex;
    flex-direction: column;
    width: 100%;
    cursor: pointer;
  }

  &__top {
    background-color: var(--color-neutral-70);
  }

  &__img-wrapper {
    overflow: hidden;
    position: relative;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;

    &:after {
      content: '';
      display: block;
      padding-bottom: calc(100% / 2 * 1);

      @include breakpoint-max(md) {
        padding-bottom: calc(100% / 5 * 3);
      }

      @include breakpoint-max(xs) {
        padding-bottom: calc(100% / 5 * 4);
      }
    }
  }

  &__overlay {
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
  }

  &__img {
    position: absolute;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }

  &__img-error {
    display: none; 
    height: rem(40px);
    width: rem(40px);
    color: var(--color-neutral-10);
  }

  &__content {
    width: 100%;
  }

  &__selection-progress-wrapper {
    width: 100%;
  }
}
