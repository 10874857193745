$block: '.logo';

#{$block} {
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;

  &__img {
    margin-right: auto;
    margin-left: auto;
    display: block;
    height: auto;
    max-height: 100%;
    width: auto;
    max-width: 100%;
  }

  &--home {
    max-height: rem(128px);
    min-height: rem(88px);
    max-width: rem(320px);
    min-width: rem(144px);
    padding-left: rem(24px);
    padding-right: rem(24px);
    
    @include breakpoint-max(xl) {
      height: rem(80px);
    }
  }

  &--header {
    height: rem(40px);
    max-width: rem(128px);
    
    @include breakpoint-max(xxl) {
      max-width: rem(96px);
    }
  }
}
