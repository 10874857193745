$block: '.home3';

#{$block} {
  overflow: hidden;
  position: relative;
  width: 100%;
  height: 100%;

  &__container {
    overflow: hidden;
    position: absolute;
    z-index: 1;
    display: flex;
    width: 100%;
    height: 100%;
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  &__bg {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 2;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
  }

  &__content {
    position: relative;
    display: block;
    width: 100%;
    height: 100%;
  }

  &__left-middle-wrapper {
    overflow: hidden;
    position: absolute;
    z-index: 5;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    flex-direction: column;
    gap: rem(40px);
    width: max-content;
    max-width: rem(400px);
    height: max-content;
    max-height: calc(100vh - 20vh);
    padding: rem(40px);
    border-radius: $br;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;

    &:after {
      content: '';
      position: absolute;
      z-index: -1;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: var(--color-neutral-100);
      opacity: 0.75;
    }
  }

  &__top-right-wrapper {
    overflow: hidden;
    position: absolute;
    z-index: 5;
    top: 0;
    right: $page-container-pd-horizontal;
    display: flex;
    flex-direction: column;
    gap: rem(24px);
    width: max-content;
    max-width: rem(400px);
    height: max-content;
    max-height: calc(100vh - 20vh);
    padding: rem(40px);
    border-radius: $br;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;

    @include breakpoint-max(xxl) {
      right: $page-container-pd-horizontal--xxl;
    }

    @include breakpoint-max(lg) {
      right: $page-container-pd-horizontal--xl;
    }

    @include breakpoint-max(lg) {
      right: $page-container-pd-horizontal--lg;
    }

    &:after {
      content: '';
      position: absolute;
      z-index: -1;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-color: var(--color-neutral-100);
      opacity: 0.75;
    }
  }
}
