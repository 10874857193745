// fluid typography
//
// READ ARTICLE for better understanding
// https://css-tricks.com/snippets/css/fluid-typography/

// used on fluid typograph mixins
@mixin fluid-type($min-vw, $max-vw, $min-font-size, $max-font-size) {
  $u1: unit($min-vw);
  $u2: unit($max-vw);
  $u3: unit($min-font-size);
  $u4: unit($max-font-size);

  @if $u1 == $u2 and $u1 == $u3 and $u1 == $u4 {
    & {
      font-size: $min-font-size;
      @media screen and (min-width: $min-vw) {
        font-size: calc(
          #{$min-font-size} + #{strip-unit($max-font-size - $min-font-size)} *
            ((100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)})
        );
      }
      @media screen and (min-width: $max-vw) {
        font-size: $max-font-size;
      }
    }
  }
}

html {
  // that mixin makes the font size vary smoothly from ($max-font:18px) to ($min-font:15px) beteween viewport width ($max-width:1440px) to ($min-width:576px)
  @include fluid-type($min-width, $max-width, $min-font, $max-font);
  // check mixin, fuction and the article link at the bottom of the file
  font-weight: normal; // same as 400
  color: var(--color-neutral-30);
  background-color: var(--color-neutral-100);
  font-family: $font-primary;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $font-secondary;
  font-weight: 700; // same as 700
  line-height: 1.1;
  margin: 0;
  padding: 0;
  color: inherit;
  letter-spacing: 0;
  color: var(--color-neutral-30);
}

p,
span,
i,
li,
a,
small,
big,
input,
select,
option,
label {
  font-family: $font-primary;
  font-weight: inherit;
  line-height: 1.4;
  margin: 0;
  padding: 0;
  color: inherit;
  font-weight: 400;
}

h1 {
  font-size: rem(24px);
}

h2 {
  font-size: rem(24px);
}

h3 {
  font-size: rem(24px);
}

h4 {
  font-size: rem(24px);
}

h5 {
  font-size: rem(24px);
}

big {
  font-size: rem(18px);
}

h6 {
  font-size: rem(14px);
}

p,
span,
i,
li,
a,
input,
select,
option,
label {
  font-size: rem(18px);
  @include breakpoint-max(xs) {
    letter-spacing: 0.02em;
  }
}

b {
  font-weight: 600;
}

small {
  font-size: 12px;
  letter-spacing: 0.02em;
}
