$block: '.project-title';

#{$block} {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;

  &__text {
    overflow: hidden;
    display: block;
    display: -webkit-box;
    font-size: inherit;
    line-height: inherit;
    font-weight: 600;
    color: var(--color-neutral-30);
    text-overflow: ellipsis;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;

    &:first-letter {
      text-transform: uppercase;
    }

    @include breakpoint-max(xl) {
      -webkit-line-clamp: 3;
    }
    
    @include breakpoint-max(md) {
      text-align: center;
      -webkit-line-clamp: 2;
    }
  }

  &--home {
    font-size: rem(24px);
    line-height: 1.4;

    @include breakpoint-max(xl) {
      font-size: rem(20px);
    }
  }

  &--header {
    min-width: rem(176px);
    font-size: rem(16px);
    line-height: rem(20px);
    
    @include breakpoint-max(xl) {
      min-width: rem(144px);
    }

    @include breakpoint-max(md) {
      max-width: 100%;
    }
  }
}
