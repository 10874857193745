$bs-header: 0px 50px 80px 0px rgba(0, 0, 0, 0.09);

$bs-dropdown: 0px 0px 1px rgba(0, 0, 0, 0.017),
  0px 0px 2.5px rgba(0, 0, 0, 0.024), 0px 0px 10px rgba(0, 0, 0, 0.03),
  0px 0px 9px rgba(0, 0, 0, 0.035), 0px 0px 33.4px rgba(0, 0, 0, 0.045),
  0px 0px 40px rgba(0, 0, 0, 0.06);

$bs-form: 0px 0px 2.2px hsla(0, 0, 0, 0.02), 0px 0px 5.3px hsla(0, 0, 0, 0.028),
  0px 0px 10px hsla(0, 0, 0, 0.035), 0px 0px 17.9px hsla(0, 0, 0, 0.042),
  0px 0px 33.4px hsla(0, 0, 0, 0.05), 0px 0px 80px hsla(0, 0, 0, 0.07);

$bs-message: 0px 0px 0.44px hsla(0, 0, 0, 0.02),
  0px 0px 1.06px hsla(0, 0, 0, 0.028), 0px 0px 2px hsla(0, 0, 0, 0.035),
  0px 0px 3.58px hsla(0, 0, 0, 0.042), 0px 0px 6.68px hsla(0, 0, 0, 0.05),
  0px 0px 16px hsla(0, 0, 0, 0.07);

$bs-message--success: 0px 0px 0.44px hsla(146, 80%, 35%, 0.02),
  0px 0px 1.06px hsla(146, 80%, 35%, 0.028),
  0px 0px 2px hsla(146, 80%, 35%, 0.035),
  0px 0px 3.58px hsla(146, 80%, 35%, 0.042),
  0px 0px 6.68px hsla(146, 80%, 35%, 0.05),
  0px 0px 16px hsla(146, 80%, 35%, 0.07);

$bs-message--error: 0px 0px 0.44px hsla(348, 80%, 35%, 0.02),
  0px 0px 1.06px hsla(348, 80%, 35%, 0.028),
  0px 0px 2px hsla(348, 80%, 35%, 0.035),
  0px 0px 3.58px hsla(348, 80%, 35%, 0.042),
  0px 0px 6.68px hsla(348, 80%, 35%, 0.05),
  0px 0px 16px hsla(348, 80%, 35%, 0.07);

$bs--large: 0px 1.3562500476837158px 2.3125px 0px rgba(0, 0, 0, 0.0227),
  0px 6.199999809265137px 6.5px 0px rgba(0, 0, 0, 0.035),
  0px 15.693750381469727px 17.4375px 0px rgba(0, 0, 0, 0.0472),
  0px 31px 40px 0px rgba(0, 0, 0, 0.07);

$bs-footer: unset;

$bs-body: unset;
