$block: '.card-order';

#{$block} {
  overflow: hidden;
  display: block;
  width: 100%;
  padding: rem(16px);
  background-color: var(--color-neutral-100);
  border-radius: $br;

  &__inner {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: rem(16px);
    width: 100%;
    height: 100%;
  }

  &__grid {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: rem(12px);
  }

  &__info-group {
    display: flex;
    flex-direction: column;
    gap: rem(2px);
  }

  &__label {
    width: fit-content;
    font-size: rem(12px);
    list-style: rem(14px);
    font-weight: 500;
  }
  
  &__value {
    width: fit-content;
    font-size: rem(14px);
    line-height: rem(16px);
    font-weight: 600;
  }

  &__status {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: rem(10px);
    padding: rem(8px) rem(16px);
    background-color: var(--color-neutral-80);
    font-weight: 600;
    letter-spacing: 0.02em;
    color: var(--color-neutral-40);
    border-radius: 99999px;

    &--denied {
      background-color: var(--color-error-90);
      color: var(--color-error-40);
    }
    &--redeem {
      background-color: var(--color-success-90);
      color: var(--color-success-40);
    }
    &--pending {
      background-color: var(--color-neutral-80);
      color: var(--color-neutral-40);
    }
    &--processing {
      background-color: var(--color-primary-90);
      color: var(--color-primary-40);
    }
  }

  &__status-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    height: rem(16px);
    width: rem(16px);
    color: inherit;
  }

  &__status-text {
    font-size: rem(12px);
    line-height: rem(16px);
    font-weight: inherit;
    color: inherit;
    letter-spacing: inherit;
  }

  &__btn-group {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: rem(8px);
  }
}
