$block: '.images-grid';

#{$block} {
  display: grid;
  gap: rem(32px);
  grid-template-columns: repeat(10, minmax(0, 1fr));
  
  @include breakpoint-max(xxxl) {
    grid-template-columns: repeat(7, minmax(0, 1fr));
  }
  
  @include breakpoint-max(xxl) {
    grid-template-columns: repeat(6, minmax(0, 1fr));
    gap: $page-container-gap;
  }

  @include breakpoint-max(xl) {
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }
  
  @include breakpoint-max(lg) {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  @include breakpoint-max(md) {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
  
  @include breakpoint-max(sm) {
    grid-template-columns: repeat(3, minmax(0, 1fr));
    gap: rem(16px);
  }

  @include breakpoint-max(xs) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  &__img-aspect-ratio {
    position: relative;
    display: block;
    width: 100%;

    &:after {
      content: '';
      display: block;
      padding-bottom: calc(100% / 1 * 1);
    }

    > * {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
}
