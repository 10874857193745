$block: '.navbar';

#{$block} {
  width: 100%;

  &__list {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-evenly;
    gap: rem(16px);
    width: 100%;

    @include breakpoint-max(xl) {
      gap: rem(8px);
    }
  }

  &__item {
    width: 100%;
  }

  &__wrapper {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: rem(8px);
    width: 100%;
    padding-top: rem(12px);
    padding-bottom: rem(12px);
    padding-left: rem(12px);
    padding-right: rem(12px);
    border-radius: $br--small;

    @include breakpoint-max(xl) {
      padding-top: rem(8px);
      padding-bottom: rem(8px);
    }

    &::after {
      content: '';
      z-index: 0;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: inherit;
      background-color: var(--color-neutral-100);
      opacity: 0;
      transition: opacity 200ms ease;
    }

    @include breakpoint-min(md) {
      &:hover {
        #{$block} {
          &__icon {
            color: var(--color-neutral-10);
          }

          &__text {
            color: var(--color-neutral-10);
          }
        }

        &::after {
          opacity: 1;
        }
      }
    }

    &--active {
      #{$block} {
        &__icon {
          color: var(--color-primary-40) !important;
        }

        &__text {
          color: var(--color-primary-40) !important;
        }
      }
    }
  }

  &__icon {
    position: relative;
    z-index: 1;
    height: rem(24px);
    width: rem(24px);
    transition: color 200ms ease;

    @include breakpoint-max(xl) {
      height: rem(20px);
      width: rem(20px);
    }
  }

  &__text {
    position: relative;
    z-index: 1;
    font-size: rem(16px);
    line-height: 1.4;
    font-weight: 500;
    color: var(--color-neutral-30);
    transition: color 200ms ease;

    @include breakpoint-max(md) {
      font-size: rem(20px);
    }
  }

  &--row {
    #{$block} {
      &__list {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        gap: rem(8px);
      }

      &__text {
        font-weight: 500;

        @include breakpoint-max(xl) {
          font-size: rem(14px);
        }
      }

      &__wrapper {
        width: max-content;
        padding-top: rem(8px);
        padding-bottom: rem(8px);
      }
    }
  }

  &--home-1 {
    overflow-y: auto;
    @extend .custom-scrollbar;

    #{$block} {
      &__text {
      }
    }
  }
}
