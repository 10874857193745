$block: '.checkout-finished';

#{$block} {
  display: flex;
  flex-direction: column;
  gap: rem(16px);
  width: 100%;

  b {
    color: var(--color-neutral-40);
  }

  &__flex-container {
    display: flex;
    flex-direction: column;
    gap: $page-container-gap;
    width: 100%;
    
  }

  &__heading-wrapper {
    display: block;
  }

  &__content {
    display: flex;
    align-items: flex-start;
    gap: $page-container-gap;
    width: 100%;
  }

  &__resume-wrapper {
    position: relative;
    z-index: 2;
    display: flex;
    flex-shrink: 0;
    max-width: rem(472px);
    width: 100%;
  }

  &__common-infos {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    gap: $page-container-gap;
    width: 100%;

    @include breakpoint-max(md) {
      flex-direction: column;
    }
  }

  &__container {
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: column;
    gap: rem(16px);
    flex-shrink: 1;
    width: 100%;
    padding: rem(32px);
    border-radius: $br;
    background-color: var(--color-neutral-100);
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.15);

    @include breakpoint-max(xxl) {
      padding: rem(24px);
    }

    @include breakpoint-max(sm) {
      padding: rem(16px);
    }

    &--resume {
      flex-shrink: 0;
      width: rem(740px);

      @include breakpoint-max(xxl) {
        width: rem(560px);
      }

      @include breakpoint-max(md) {
        width: 100%;
      }
    }
  }

  &__group {
    display: flex;
    flex-direction: column;
    gap: $page-container-gap;

    @include breakpoint-max(xl) {
      gap: 8px;
    }

    &--row {
      flex-direction: row;
    }

    &--align-start {
      align-items: flex-start !important;
    }

    &--padding-top-bottom {
      padding-top: rem(12px);
      padding-bottom: rem(12px);
    }
  }

  &__text {
    display: block;
    width: 100%;
    font-size: rem(18px);
    line-height: 1.3;
    text-align: center;

    @include breakpoint-max(xxl) {
      font-size: rem(14px);
    }

    &--animate {
      color: var(--color-neutral-10);
    }

    &--align-left {
      text-align: left;

      @include breakpoint-max(sm) {
        text-align: center;
      }
    }

    &--small {
      font-size: rem(14px);
    }

    &--link {
      color: var(--color-primary-50);
      text-decoration: underline;
      border: unset;
      background-color: unset;
      transition: 0.2s ease;

      @include breakpoint-min(md) {
        &:hover {
          color: var(--color-primary-30);
        }
      }
    }

    &--success {
      color: var(--color-success-40);
    }

    &--bold {
      font-weight: 500;
    }

    &--hidden {
      max-height: 0px;
      opacity: 0;
    }
  }

  &__line {
    width: 100%;
    height: 1px;
    margin: rem(16px) 0;
    border-bottom: 1px solid var(--color-neutral-50);

    @include breakpoint-max(xl) {
      margin: 8px 0;
    }
  }
  &__info-group {
    display: flex;
    justify-content: space-evenly;
    align-items: flex-start;
    gap: rem(12px);
    width: 100%;

    @include breakpoint-max(sm) {
      flex-direction: column;
      align-items: center;
      gap: rem(16px);
    }
  }

  &__info {
    display: flex;
    flex-direction: column;
    gap: 8px;

    @include breakpoint-max(sm) {
      gap: 4px;
    }
  }

  &__btn-group {
    display: flex;
    flex-wrap: wrap;
    gap: rem(8px);
    width: 100%;

    > .btn {
      width: max-content;
      flex: 1;
    }
  }
}

.payment-details {
  $blockname: &;
  display: flex;
  flex-direction: column;
  gap: $page-container-gap;
  width: 100%;

  @include breakpoint-max(sm) {
    flex-direction: column-reverse;
    align-items: center;
  }

  &__steps {
    display: flex;
    flex-direction: column;
    gap: rem(16px);
    width: 100%;
    padding: 0;
    margin: 0;
    counter-reset: steps;

    @include breakpoint-max(sm) {
      flex-direction: column;
      align-items: center;
      gap: rem(16px);
      padding-bottom: rem(16px);
    }

    + #{$blockname}__barcode-group {
      #{$blockname}__image-wrapper--square {
        width: rem(180px);

        @include breakpoint-max(md) {
          margin-left: auto;
          margin-right: auto;
        }
      }
    }
  }

  &__step {
    display: flex;
    gap: 8px;
    width: 100%;

    @include breakpoint-max(sm) {
      gap: rem(8px);

      [class*='__text'] {
        text-align: left;
      }
    }

    &:before {
      counter-increment: steps;
      content: counter(steps);
      position: relative;
      top: rem(-4px);
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      color: var(--color-neutral-10);
      height: rem(24px);
      width: rem(24px);
      border-radius: 999px;
      background-color: var(--color-neutral-90);
    }
  }

  &__barcode-group {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-shrink: 0;
    gap: rem(16px);
    width: 100%;
    max-width: 100%;

    @include breakpoint-max(md) {
      gap: rem(8px);
    }
  }

  &__image-wrapper {
    display: flex;
    flex-shrink: 0;
    position: relative;
    width: fit-content;
    width: rem(96px);

    @include breakpoint-max(md) {
      width: 100%;
      align-items: center;
      justify-content: center;
    }

    &--square {
      &:after {
        content: '';
        display: block;
        padding-bottom: 100%;
      }

      #{$blockname}__img {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        object-fit: contain;
        object-position: center;
      }
    }
  }

  &__img {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center;
  }

  &__btn-group {
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 100%;
  }

  &__btn {
  }

  &__icon {
    display: block;
    width: rem(96px);
    height: rem(96px);
    margin-left: auto;
    margin-right: auto;

    ::ng-deep svg {
      width: inherit !important;
      height: inherit !important;
    }
  }

  &--column-reverse {
    flex-direction: column-reverse;
  }
}
