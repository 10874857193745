$header-font-family: $font-secondary;
$header-height: rem(90px);
$header-logo-height: rem(40px);
$header-height--sticky: rem(60px);
$header-hamburguer-menu-size: rem(48px);
$header-container-gap: rem(32px);

$header-height--xl: rem(70px);
$header-logo-height--xl: rem(30px);
$header-container-gap--xl: rem(24px);

$header-height--md: rem(80px);
$header-logo-height--md: rem(40px);
$header-height--sticky--md: rem(60px);
$header-container-gap--md: rem(24px);
$header-menu-width--md: rem(340px);
$header-menu-spacing--md: rem(24px);
$header-medias-height--md: rem(40px);
$header-medias-total-height--md: calc(
  $header-menu-spacing--md * 2 + $header-medias-height--md
);
