$block: '.fullscreen-loader';

#{$block} {
  visibility: visible;
  position: fixed;
  z-index: $zindex-loader;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  transition: all .3s ease;

  &:after {
    content: '';
    position: absolute;
    z-index: -1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: $backdrop-color;
    opacity: $backdrop-opacity;
  }

  &__container {
    position: relative;
    z-index: 1;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  &__spinner-wrapper {
    overflow: hidden;
    position: relative;
    width: fit-content;
    height: fit-content;
    padding: rem(6px);
    border-radius: $br--rounded;
    box-shadow: $bs--large;
    
    &:after {
      content: '';
      position: absolute;
      z-index: -1;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-color: var(--color-neutral-10);
      opacity: .9;
    }
  }

  &__text {
    font-size: rem(20px);
    letter-spacing: 0.02em;
    font-weight: 500;
    color: var(--color-neutral-100);
  }

  &--hidden {
    visibility: hidden;
    z-index: $zindex-hidden;
    opacity: 0;
  }
}
