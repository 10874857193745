$block: '.project-cover';

#{$block} {
  position: relative;
  display: block;
  width: 100%;
  height: 100%;

  &__img {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
}
