$block: '.checkout';

#{$block} {
  &__flex-container {
    display: flex;
    flex-direction: column;
    gap: $page-container-gap;
    width: 100%;
  }

  &__heading-wrapper {
    display: block;
  }

  &__content {
    display: flex;
    align-items: flex-start;
    gap: $page-container-gap;
    width: 100%;
  }

  &__resume-wrapper {
    position: relative;
    z-index: 2;
    display: flex;
    flex-shrink: 0;
    max-width: rem(472px);
    width: 100%;
    
    @include breakpoint-max(xxl) {
      max-width: rem(440px);
    }
  }

  &__payment-methods-wrapper {
    position: relative;
    z-index: 1;
    display: flex;
    width: 100%;
  }
}
