// container horizontal padding
$page-container-pd-horizontal: rem(140px);
$page-container-pd-horizontal--xxl: rem(80px);
$page-container-pd-horizontal--xl: rem(64px);
$page-container-pd-horizontal--lg: rem(48px);
$page-container-pd-horizontal--sm: rem(24px);

// container gap
$page-container-gap: rem(24px);
$page-container-gap--large: rem(32px);

// wrapper gap
$page-wrapper-gap: $page-container-gap;

// section vertical spacing
$page-AUX-section-spacing-between-vertical: rem(24px);
$page-AUX-section-spacing-between-vertical--large: rem(32px);
$page-AUX-section-spacing-vertical-first: rem(32px);
$page-AUX-section-spacing-vertical-last: rem(32px);

// section vertical padding
$page-section-pd-vertical-first: $page-AUX-section-spacing-vertical-first;
$page-section-pd-vertical-last: $page-AUX-section-spacing-vertical-last;
$page-section-pd-vertical: calc($page-AUX-section-spacing-between-vertical / 2);
$page-section-pd-vertical--large: calc($page-AUX-section-spacing-between-vertical--large / 2);

// body
$page-body-bg-color: var(--color-neutral-90);
