$block: '.footer';

#{$block} {
  overflow: hidden;
  position: relative;
  z-index: $zindex-footer;
  
  @include breakpoint-max(md) {
    height: unset;
    padding-top: rem(16px);
    padding-bottom: 0;
  }

  &__container {
    align-items: center;
    justify-content: center;
    width: 100%;

    @include breakpoint-max(md) {
      gap: rem(48px)!important;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    padding-top: rem(24px);
    width: 100%;
    align-items: center;
  }

  &__logo-group {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: rem(8px);
  }

  &__logo-call {
    font-size: rem(14px);
    line-height: rem(20px);
    font-weight: 500;
  }

  &__logo {
    max-width: 205px;
    max-height: 100%;
    width: auto;
    height: auto;
  }

  &__copyright {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: $page-container-gap;
    width: 100%;
    padding-top: rem(8px);
    padding-bottom: rem(8px);
    
    @include breakpoint-max(md) {
      flex-direction: column;
      gap: 0;
    }

    &:after {
      content: '';
      position: absolute;
      z-index: 1;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      display: block;
      width: 100vw;
      height: 100%;
      background-color: var(--color-neutral-80);
      
      @include breakpoint-max(md) {
        height: 100%;
      }
    }
  }

  &__left {
    position: relative;
    z-index: 2;
    display: flex;
    align-items: center;
    gap: rem(54px);
  }

  &__link {
    height: fit-content;

    @include breakpoint-min(md) {
      &:hover {
        #{$block} {
          &__text {
            color: var(--color-primary-60);
            text-decoration: underline;
          }
        }
      }
    }
  }

  &__text {
    display: flex;
    align-items: center;
    height: rem(24px);
    padding-right: rem(8px);
    padding-left: rem(8px);
    font-size: rem(12px);
    font-weight: 500;
    color: var(--color-neutral-40);
    transition: color 200ms ease;
  }

  &__right {
    position: relative;
    z-index: 2;
    display: flex;
    align-items: center;
    width: fit-content;
  }
}
