$block: '.dropdown';

#{$block} {
  position: relative;
  
  &__list-box {
    position: absolute;
    top: 100%;
    display: flex;
    flex-direction: column;
    gap: rem(8px);
    width: fit-content;
    height: fit-content;
    padding: rem(12px)  ;
    background-color: var(--color-neutral-100);
    border-radius: $br;
    box-shadow: $bs-dropdown;
  }

  &__item-btn {
    width: 100%;
    justify-content: flex-start;

    @include breakpoint-min(md) {
      &:hover,
      &:active {
        background-color: var(--color-neutral-90)!important;
      }
    }
  }

  &__link {

  }

  &--left {
    #{$block}__list-box {
      left: 0;
    }
  }

  &--right {
    #{$block}__list-box {
      right: 0;
    }
  }
}
