$zindex-loader: 9999;
$zindex-dialog--3: 599;
$zindex-dialog--2: 499;
$zindex-dialog: 399;
$zindex-fixed-components: 299;
$zindex-header: 199;
$zindex-select-options: 29;
$zindex-main: 19;
$zindex-footer: 9;
$zindex-hidden: -999;
