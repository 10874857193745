$block: '.heading';

#{$block} {
  // start: abstracts
  $heading-height: rem(52px);
  $heading-gap: rem(8px);
  $heading-width-left: $heading-height;
  $heading-width-right: calc($heading-height + $heading-gap);
  $heading-height--xl: rem(48px);
  $heading-gap--xl: $heading-gap;
  $heading-width-left--xl: $heading-height--xl;
  $heading-width-right--xl: calc($heading-height--xl + $heading-gap--xl);
  // end: abstracts
  position: relative;
  display: flex;
  gap: $heading-gap;
  height: $heading-height;
  width: 100%;

  @include breakpoint-max(xl) {
    gap: $heading-gap--xl;
    height: $heading-height--xl;
  }

  &__left {
    display: flex;
    flex-shrink: 0;
    flex-grow: 0;
    width: $heading-width-left;
    height: 100%;

    @include breakpoint-max(xl) {
      width: $heading-width-left--xl;
    }
  }

  &__content {
    overflow: hidden;
    position: relative;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    font-size: rem(18px);
    line-height: 1.3;
    color: var(--color-neutral-40);
    font-weight: 500;
    border-radius: $br;
    background-color: var(--color-neutral-90);
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.14) inset;

    &--grow {
    }
  }

  &__back-btn {
    position: relative;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    width: $heading-height;
    height: $heading-height;
    border: unset;
    transition: all 200ms;

    @include breakpoint-max(xl) {
      width: $heading-height--xl;
      height: $heading-height--xl;
    }

    @include breakpoint-min(md) {
      &:hover {
        background-color: var(--color-neutral-80);

        #{$block}__icon {
          color: var(--color-neutral-10);
        }
      }
    }
  }

  &__icon {
    height: rem(20px);
    width: rem(20px);
  }

  &__right {
    display: flex;
    flex-shrink: 1;
    flex-grow: 1;
    width: $heading-width-right;
    height: 100%;

    @include breakpoint-max(xl) {
      width: $heading-width-right--xl;
    }
  }

  &__title-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: rem(12px);
  }

  &__title {
    display: block;
    width: fit-content;
    font-size: inherit;
    line-height: inherit;
    color: inherit;
    font-weight: inherit;
  }
}
