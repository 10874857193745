$block: '.download';

#{$block} {
  position: relative;

  &__content {
    display: flex;
    flex-direction: column;
    gap: $page-container-gap;
    width: 100%;
  }

  &__tab-actions-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: $page-container-gap;
    width: 100%;
    max-width: 100%;

    @include breakpoint-max(md) {
      flex-direction: column;
      align-items: flex-start;
    }
  }

  &__tab-wrapper {
    display: flex;
    width: 100%;
  }

  &__actions-wrapper {
    flex-shrink: 0;
    display: flex;
    align-items: center;
    gap: $page-container-gap;

    @include breakpoint-max(md) {
      align-self: flex-end;
    }
  }

  &__btn-wrapper {
  }

  &__btn {
  }

  &__counter {
    font-size: rem(16px);
    font-weight: 500;
    white-space: nowrap;
  }

  &__grid-wrapper {
    width: 100%;
  }
}
