$block: '.payment-methods';

#{$block} {
  position: relative;
  display: block;
  height: 100%;
  width: 100%;
  padding: rem(32px);
  background-color: var(--color-neutral-100);
  border-radius: $br;
  box-shadow: 0px 0.8432000279426575px 4.454400062561035px 0px hsla(0, 0%, 0%, 0.01),
    0px 3.7695999145507812px 10.291199684143066px 0px hsla(0, 0%, 0%, 0.02),
    0px 9.37440013885498px 23.50079917907715px 0px hsla(0, 0%, 0%, 0.03),
    0px 18.25279998779297px 50.07360076904297px 0px hsla(0, 0%, 0%, 0.04),
    0px 31px 96px 0px hsla(0, 0%, 0%, 0.05);

  &__inner {
    display: flex;
    flex-direction: column;
    gap: $page-container-gap;
    width: 100%;
    border-radius: inherit;
  }

  &__flex-group {
    display: flex;
    flex-direction: column;
    gap: rem(16px);
    width: 100%;

    &--top {
    }
    &--middle {
    }
    &--bottom {
    }
  }

  &__heading {
    display: flex;
    flex-direction: column;
    gap: (8px);
  }

  &__title {
    font-size: rem(18px);
    line-height: rem(24px);
    font-weight: 700;
    color: var(--color-neutral-40);
  }

  &__tabs-observation-wrapper {
    display: flex;
    flex-direction: column;
    gap: rem(8px);
    width: 100%;
  }

  &__tabs-wrapper {
    width: 100%;
  }

  &__observation {
    width: 100%;
    font-size: rem(12px);
    line-height: 1.3;
    font-weight: 500;
    letter-spacing: 0.02em;
    text-align: right;
    color: var(--color-neutral-10);
  }

  &__tab-content {
    display: flex;
    flex-direction: column;
    gap: $page-container-gap;
    width: 100%;

    &--billet {
    }
    &--credit-card {
    }
    &--pix {
    }
  }

  &__text {
    display: block;
    width: 100%;
    font-size: rem(16px);
    line-height: rem(24px);
    font-weight: 500;

    &--center {
      text-align: center;
    }
  }

  &__form-wrapper {
    display: block;
  }

  &__form {
    width: 100%;
  }

  &__error-message {
    padding-bottom: rem(24px);
  }

  &__form-container {
    display: grid;
    grid-template-columns: 1fr repeat(2, 116px);
    grid-template-rows: repeat(3, auto);
    grid-column-gap: 16px;
    grid-row-gap: 16px;

    .form__group:nth-child(1) {
      grid-area: 1 / 1 / 2 / 2;
    }

    .form__group:nth-child(2) {
      grid-area: 1 / 2 / 2 / 3;
    }

    .form__group:nth-child(3) {
      grid-area: 1 / 3 / 2 / 4;
    }

    .form__group:nth-child(4) {
      grid-area: 2 / 1 / 3 / 2;
    }

    .form__group:nth-child(5) {
      grid-area: 2 / 2 / 3 / 4;
    }

    .form__group:nth-child(6) {
      grid-area: 3 / 1 / 4 / 4;
    }
  }

  &__total-value-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  &__total-value {
    display: block;
    font-size: rem(18px);
    line-height: rem(22px);
    font-weight: bold;
    text-align: center;
    letter-spacing: 0.04em;

    &--primary {
      display: inline-block;
      color: var(--color-primary-40);
    }

    &--500 {
      font-weight: 500;
    }
  }
}
