$block: '.home2';

#{$block} {
  overflow: hidden;
  position: relative;
  width: 100%;
  height: 100%;

  &__container {
    overflow: hidden;
    position: relative;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    height: 100%;
    padding-left: 0!important;
    padding-right: 0!important;
  }

  &__bg {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 2;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
  }

  &__content-wrapper {
    position: absolute;
    z-index: $zindex-header;
    left: 0;
    top: 0;
    width: 100%;
    height: rem(96px);

    &:after {
      content: '';
      position: absolute;
      z-index: -1;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-color: var(--color-neutral-100);
      opacity: 0.75;
    }
  }
  
  &__content {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: $header-container-gap;
    width: 100%;
    height: inherit;
  }  

  &__left-wrapper {
    display: flex;
    align-items: center;
    flex-shrink: 0;
    gap: inherit;
    width: max-content;
  }
  
  &__navbar-wrapper {
    display: flex;
    flex-shrink: 0;
    width: max-content;
  }
  
  &__socialbar-wrapper {
    width: 100%;
  }
}
