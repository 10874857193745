$block: '.no-content';

#{$block} {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: rem(40px);
  max-width: calc(560px + (2 * 40px));
  padding: 40px;
  margin-left: auto;
  margin-right: auto;

  &__heading {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: rem(24px);
  }

  &__title {
    font-size: rem(24px);
    line-height: 1.3;
    font-weight: 700;
    color: var(--color-neutral-40);
  }

  &__desc {
    font-size: rem(18px);
    line-height: 1.3;
    font-weight: 500;
    color: var(--color-neutral-30);
  }
}
