$block: '.fixed-selec-summ';

#{$block} {
  $component-width: rem(130px);
  overflow: hidden;
  position: fixed;
  z-index: $zindex-fixed-components;
  top: 50vh;
  right: 0;
  transform: translate(0, -50%);
  width: $component-width;
  height: fit-content;
  border-top-left-radius: $br--small;
  border-bottom-left-radius: $br--small;
  box-shadow:
    0px 1px 2.2px rgba(0, 0, 0, 0.02),
    0px 2.3px 5.3px rgba(0, 0, 0, 0.028),
    0px 4.4px 10px rgba(0, 0, 0, 0.035),
    0px 7.8px 17.9px rgba(0, 0, 0, 0.042),
    0px 14.6px 33.4px rgba(0, 0, 0, 0.05),
    0px 35px 80px rgba(0, 0, 0, 0.07);
  opacity: 1;
  transition: transform 0.2s ease, opacity 0.1s ease;
  
  @include breakpoint-max(md) {
    top: unset;
    bottom: rem(16px);
    right: 50%;
    transform: translateX(50%);
    width: calc(100% - $page-container-pd-horizontal--lg * 2);
    max-width: rem(400px);
    border-radius: $br--small;
  }
  
  @include breakpoint-max(sm) {
    bottom: rem(12px);
    width: calc(100% - $page-container-pd-horizontal--sm * 2);
  }

  &__inner {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: rem(12px);
    padding: rem(16px);
    width: 100%;
    height: auto;
    background-color: var(--color-neutral-100);

    @include breakpoint-max(md) {
      flex-direction: row;
      justify-content: space-between;
      gap: rem(32px);
      padding: rem(12px) rem(16px);
      padding-bottom: rem(30px);
    }
  }

  &__gorup {
    &--progress {
      width: 65px;
      height: 65px;

      @include breakpoint-max(md) {
        display: none;
      }
    }

    &--linear-progress{

      @include breakpoint-min(md) {
        display: none;
      }
    }
  }

  &__counter {
    display: block;
    height: 100%;
    border-radius: $br--small;
  }
  
  &__number {
    font-size: rem(20px);
    line-height: 1;
    font-weight: bold;
  }

  &__bar {
    overflow: hidden;
    position: absolute;
    z-index: 1;
    left: rem(16px);
    bottom: rem(12px);
    display: block;
    height: rem(8px);
    width: calc(100% - rem(16px) * 2);
    border-radius: $br;
    background-color: var(--color-neutral-80);

    &--progress {
      position: absolute;
      z-index: 2;
      left: 0;
      top: 0;
      width: 50%;
      height: 100%;
      background-color: var(--color-success-60);
    }
  }

  &__text {
    font-size: rem(16px);
    font-weight: 600;

    @include breakpoint-max(md) {
      font-size: rem(18px);
    }
  }

  &--hidden {
    transform: translate(100%, -50%);
    opacity: 0;  
    
    @include breakpoint-max(md) {
      transform: translate(50%, 100%);
    }
  }
}
