$block: '.page';

#{$block} {
  &__html {
    overflow-y: auto;
    overflow-x: hidden;
    width: 100%;
    min-height: 100vh;
    height: 100%;

    &--offcanvas-is-open,
    &--overflow-y-hidden {
      overflow-y: hidden;
      padding-right: $scrollbar-width;
    }
  }
  
  &__body {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    width: 100%;
    min-height: 100vh;
    background-color: $page-body-bg-color;

    &--h100 {
      height: 100vh;
      max-height: 100vh;
    }
  }

  &__main {
    position: relative;
    z-index: $zindex-main;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    width: 100%;
    height: 100%;
  }

  &__section {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-top: $page-section-pd-vertical;
    padding-bottom: $page-section-pd-vertical;

    &:first-of-type {
      padding-top: $page-section-pd-vertical-first;
    }

    &:last-of-type {
      padding-bottom: $page-section-pd-vertical-last;
    }

    &--grow {
      flex-grow: 1;
      > #{$block} {
        &__container {
          flex-grow: 1;
        }
      }
    }

    &--bottom-padding-lg {
      padding-bottom: $page-section-pd-vertical--large;

      + #{$block} {
        &__container {
          padding-top: $page-section-pd-vertical--large;
        }
      }
    }

    &--no-vertical-pd {
      padding-top: 0 !important;
      padding-bottom: 0 !important;
    }
  }

  &__container {
    display: flex;
    flex-direction: column;
    gap: $page-container-gap;
    height: 100%;
    width: 100%;
    max-width: unset;
    padding-left: $page-container-pd-horizontal;
    padding-right: $page-container-pd-horizontal;
    margin-left: auto;
    margin-right: auto;

    &--row {
      flex-direction: row;
    }

    &--w-100 {
      padding-left: 0;
      padding-right: 0;
    }

    &--w-small {
      max-width: calc(rem(912px) + $page-container-pd-horizontal * 2);
    }

    &--w-xsmall {
      max-width: calc(rem(672px) + $page-container-pd-horizontal * 2);
    }

    &--grow {
      flex-grow: 1;
    }

    &--center {
      align-items: center;
      justify-content: center;
    }

    @include breakpoint-max(xxl) {
      padding-left: $page-container-pd-horizontal--xxl;
      padding-right: $page-container-pd-horizontal--xxl;
    }

    @include breakpoint-max(xl) {
      padding-left: $page-container-pd-horizontal--xl;
      padding-right: $page-container-pd-horizontal--xl;
    }

    @include breakpoint-max(lg) {
      padding-left: $page-container-pd-horizontal--lg;
      padding-right: $page-container-pd-horizontal--lg;
    }

    @include breakpoint-max(sm) {
      padding-left: $page-container-pd-horizontal--sm;
      padding-right: $page-container-pd-horizontal--sm;
    }
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
    gap: $page-wrapper-gap;
    width: 100%;

    &--row {
      flex-direction: row;
    }

    &--no-gap {
      gap: rem(0px);
    }
  }

  &__component-wrapper {
    display: flex;
    flex-direction: column;
    gap: $page-wrapper-gap;
    width: 100%;

    &--row {
      flex-direction: row;

      @include breakpoint-max(md) {
        flex-direction: column;
      }
    }

    &--no-gap {
      gap: rem(0px);
    }
  }

  &__bg-area {
    display: flex;
    flex-direction: column;
    gap: rem(32px);
    width: 100%;
    padding: rem(32px);
    border: 1px solid var(--color-neutral-80);
    border-radius: $br;
    background-color: var(--color-neutral-100);
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.15);

    &--spacing-small {
      gap: rem(24px);
      padding: rem(24px);
    }

    &--padding-large {
      padding: rem(40px);
    }

    &--no-bs {
      box-shadow: none;
    }
    
    @include breakpoint-max(xl) {
      gap: rem(24px);
      padding: rem(24px);
    }
    
    @include breakpoint-max(sm) {
      gap: rem(12px);
      padding: rem(12px);
    }
  }

  &__heading {
    display: flex;
    flex-direction: column;
    gap: rem(12px);
    width: 100%;
  }

  &__title {
    width: fit-content;
    font-size: rem(20px);
    font-weight: 500;
    line-height: 1.2;
    color: var(--color-primary-40);

    &::first-letter {
      text-transform: uppercase;
    }

    &--large {
      font-size: rem(24px);
    }

    &--small {
      font-size: rem(18px);
    }

    &--color-neutral {
      color: var(--color-neutral-40);
    }

    &--bold {
      font-weight: 700;
    }
  }
  
  &__desc {
    width: 100%;
    font-size: rem(16px);
    font-weight: 400;
    color: var(--color-neutral-30);
  }

  &__btn-group {
    display: grid;
    grid-gap: rem(16px);
    width: 100%;
  }
}
