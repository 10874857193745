$block: '.image-viewer';

#{$block} {
  position: fixed;
  z-index: $zindex-dialog--2;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;

  &__backdrop {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: $backdrop-color--image-viewer;
    opacity: $backdrop-opacity;
  }

  &__container {
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: column;
    gap: $image-viewer-container-gap;
    width: 100%;
    height: 100%;
    max-width: $image-viewer-max-width;
    max-height: $image-viewer-max-height;
    padding-left: $image-viewer-pd-horizontal;
    padding-right: $image-viewer-pd-horizontal;
    padding-top: $image-viewer-pd-vertical;
    padding-bottom: $image-viewer-pd-vertical;
  }

  &__actions {
    display: flex;
    justify-content: space-between;
    flex-shrink: 0;
  }

  &__action-group {
    display: flex;
    gap: rem(40px);
  }

  &__btn {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: $image-viewer-btn-size;
    height: $image-viewer-btn-size;
    border-radius: $btn-border-radius;
    border: $btn-border-width solid;
    border-color: transparent;
    transition: border-color 0.2s ease, background-color 0.2s ease,
      color 0.2s ease;

    @include breakpoint-min(md) {
      &:hover {
        border-color: $image-viewer-btn-hover !important;
        background-color: $image-viewer-btn-hover !important;
      }
    }

    &--active {
      background-color: var(--color-primary-60) !important;
      border-color: var(--color-primary-60) !important;

      #{$block} {
        &__btn-icon {
          color: var(--color-neutral-10) !important;
        }
      }

      @include breakpoint-min(md) {
        &:hover {
          background-color: var(--color-primary-40) !important;
          border-color: var(--color-primary-40) !important;

          #{$block} {
            &__btn-icon {
              color: var(--color-neutral-100) !important;
            }
          }
        }
      }
    }
  }

  &__btn-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    width: $image-viewer-btn-icon-size;
    height: $image-viewer-btn-icon-size;
    color: $image-viewer-btn-color !important;
  }

  &__slider-wrapper {
    position: relative;
    width: 100%;
    height: calc(100% - $image-viewer-actions-height);
  }

  &__skeleton {
    position: absolute;
    z-index: 1;
    display: none;
    height: 100%;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    background: #eee;
    background: linear-gradient(
      110deg,
      var(--color-neutral-70) 8%,
      var(--color-neutral-90) 18%,
      var(--color-neutral-70) 33%
    );
    background-size: 200% 100%;
    animation: 1.5s shine linear infinite;
    border-radius: $image-viewer-img-border;
  }

  &__slider {
    position: relative;
    height: 100%;
  }

  &__slider-container {
    display: flex;
    align-items: stretch;
    height: 100%;
  }

  &__slider-item {
    display: flex;
    align-items: center;
    justify-content: center;
    height: auto;
  }

  &__img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center;
    border-radius: $image-viewer-img-border;
  }

  &__slider-arrow {
    @extend #{$block}__btn;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);

    &--left {
      right: calc(100% + $image-viewer-arrow-gap);
    }
    &--right {
      left: calc(100% + $image-viewer-arrow-gap);
    }

    &--disabled {
      opacity: 0;
      visibility: hidden;
      pointer-events: none;
    }
  }

  &__slider-arrow-icon {
    @extend #{$block}__btn-icon;
    width: $image-viewer-btn-icon-size * 1.5;
    height: $image-viewer-btn-icon-size * 1.5;
  }

  &--is-loading {
    #{$block} {
      &__skeleton {
        z-index: 1;
        display: block;
      }

      &__slider {
      }
    }
  }
}

@keyframes shine {
  to {
    background-position-x: -200%;
  }
}
