$block: '.access-hub';

#{$block} {
  &__bg {
    position: absolute;
    z-index: 2;
    width: 100%;
    height: 100%;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }

  &__container {
    overflow-y: auto;
    position: relative;
    z-index: 3;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    max-width: rem(420px);
    max-height: 90vh;
    padding: rem(40px) !important;
    border-radius: $br--xlarge;
    box-shadow: $bs--large;

    @include breakpoint-max(xl) {
      padding: rem(32px) !important;
    }
  }
}
