$block: '.order-details';

#{$block} {
  position: relative;
  width: 100%;

  &__flex-container {
    display: flex;
    flex-direction: column;
    gap: $page-container-gap;
    width: 100%;
  }

  &__heading-wrapper {
    display: block;
    width: 100%;
  }

  &__content {
    display: flex;
    align-items: flex-start;
    gap: $page-container-gap;
    width: 100%;

    @include breakpoint-max(md) {
      flex-direction: column;
    }
  }

  &__order-resume-wrapper {
    display: flex;
    flex-shrink: 0;
    max-width: rem(640px);

    @include breakpoint-max(md) {
      max-width: 100%;
      width: 100%;
    }
  }

  &__details-wrapper {
    display: flex;
    flex-direction: column;
    gap: $page-container-gap;
    width: 100%;
  }

  &__colum-group {
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: column;
    width: 100%;
    box-shadow: 0px 0.8432000279426575px 4.454400062561035px 0px
        rgba(0, 0, 0, 0.0138),
      0px 3.7695999145507812px 10.291199684143066px 0px rgba(0, 0, 0, 0.0219),
      0px 9.37440013885498px 23.50079917907715px 0px rgba(0, 0, 0, 0.0281),
      0px 18.25279998779297px 50.07360076904297px 0px rgba(0, 0, 0, 0.0362),
      0px 31px 96px 0px rgba(0, 0, 0, 0.05);

    + #{$block} {
      &__colum-group {
        z-index: 2;
      }
    }
  }

  &__title {
    font-size: rem(18px);
    font-weight: 700;
    color: var(--color-neutral-40);
  }

  &__infos-grid {
    display: grid;
    grid-template-columns: rem(180px) 1fr;
    gap: $page-container-gap;
  }

  &__info-name {
    display: flex;
    flex-direction: column;
    gap: rem(2px);
    width: 100%;
  }

  &__text {
    font-size: rem(16px);
    line-height: rem(20px);
    width: fit-content;

    &--small {
      font-size: rem(12px);
    }

    &--emphasys {
      font-weight: bold;
    }
  }

  &__info-value {
    @extend #{$block}__info-name;
    align-items: flex-end;
  }

  &__status-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: $page-container-gap;
    width: 100%;
  }

  &__status {
    display: flex;
    flex-shrink: 0;
    max-width: rem(200px);
    width: 100%;
  }
}
