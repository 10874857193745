$block: '.selection-resume';

#{$block} {
  display: block;
  width: 100%;

  &__inner {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: rem(32px);
    width: 100%;
    height: 100%;
    
    @include breakpoint-max(xxl) {
      gap: $page-container-gap;
    }
    
    @include breakpoint-max(md) {
      gap: rem(16px);
    }
  }

  &__top-content {
    display: flex;
    flex-direction: column;
    gap: $page-container-gap;
    
    @include breakpoint-max(xxl) {
      gap: rem(16px);
    }
    
    @include breakpoint-max(md) {
      gap: rem(12px);
    }
  }

  &__heading {
    display: flex;
    flex-direction: column;
    gap: rem(8px);
  }

  &__title {
    display: block;
    font-size: rem(24px);
    font-weight: 600;
    color: var(--color-neutral-30);

    @include breakpoint-max(xxl) {
      font-size: rem(20px);
    }

    @include breakpoint-max(md) {
      font-size: rem(18px);
    }

    &:first-letter {
      text-transform: uppercase;
    }
  }

  &__text {
    width: fit-content;
    font-size: rem(18px);
    line-height: 1.4;

    @include breakpoint-max(xl) {
      font-size: rem(16px);
    }

    &--emphasys {
      font-weight: 500;
      color: var(--color-neutral-40);
    }
  }

  &__infos-group {
    display: flex;
    flex-direction: column;
    gap: $page-container-gap;
    width: 100%;

    @include breakpoint-max(xxl) {
      gap: rem(16px);
    }

    @include breakpoint-max(md) {
      gap: rem(8px);
    }
  }

  &__btn-group {
    display: flex;
    flex-direction: column;
    gap: rem(16px);
    width: 100%;

    @include breakpoint-max(xxl) {
      gap: rem(8px);
    }
  }

  &__btn {
    &--error {
      &:disabled {
        color: $btn-color-font--error !important;
        border-color: $btn-color-border--error !important;
        outline-color: $btn-color-ring--error !important;
        background-color: $btn-color-bg--error !important;
      }
    }
  }
}
