$block: '.offcanvas';

#{$block} {
  position: fixed;
  left: 100%;
  z-index: 20;
  display: flex;
  height: 100vh;
  width: 100%;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity 0.3s, left 0.5s;

  &__mobile-trigger {
    position: absolute;
    right: rem(24px);
    top: rem(16px);
    width: max-content;
    height: max-content;
  }

  &__bg {
    height: 100%;
    width: 100%;
    --tw-bg-opacity: 0.9;
    background-color: hsl(0 0% 100% / var(--tw-bg-opacity));
  }

  &__container {
    position: absolute;
    top: 0px;
    display: flex;
    height: 100%;
    width: max-content;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: rem(40px);
    padding: 1.75rem;
  }
}

.page__html--offcanvas-is-open {
  overflow: hidden;

  .offcanvas {
    left: 0px;
    opacity: 1;
  }
}
