// font family
$btn-font-family: $font-primary;
$form-font-family: $font-primary;

// transition
$btn-transition-timing: 0.2s;
$btn-transition-time-function: ease;
$btn-transition: background-color $btn-transition-timing
    $btn-transition-time-function,
  border-color $btn-transition-timing $btn-transition-time-function,
  color $btn-transition-timing $btn-transition-time-function;

//  REGULAR SIZE  //  REGULAR SIZE  //  REGULAR SIZE  //
//  REGULAR SIZE  //  REGULAR SIZE  //  REGULAR SIZE  //
//borders sizing
$btn-border-width: 1px;
$btn-border-radius: $br--small;
//font sizing
$btn-font-size: rem(16px);
$btn-line-height: rem(20px);
$btn-letter-spacing: 0.01em;
$btn-font-weight: 600;
$form-font-weight: 400;
$btn-text-transform: unset;
//general sizing
$btn-padding-top: rem(10px);
$btn-padding-bottom: $btn-padding-top;
$btn-padding-right: rem(16px);
$btn-padding-left: $btn-padding-right;
$btn-height: calc($btn-line-height + $btn-padding-top + $btn-padding-bottom + ($btn-border-width * 2));
//icons sizing
$btn-icon-size: $btn-line-height;
$btn-icon-gap: rem(10px);
$btn-icon-padding-right: $btn-padding-top;
$btn-icon-padding-left: $btn-padding-top;
//outline sizing
$btn-ring-width: 1px;
$btn-ring-offset: 4px;

//  SMALL SIZE  //  SMALL SIZE  //  SMAL SIZE  //
//  SMALL SIZE  //  SMALL SIZE  //  SMAL SIZE  //
//borders sizing
$btn-border-width--small: 1px;
$btn-border-radius--small: $br--small;
//font sizing
$btn-font-size--small: rem(14px);
$btn-line-height--small: rem(18px);
$btn-letter-spacing--small: 0.02em;
$btn-font-weight--small: 600;
$btn-text-transform--small: unset;
//general sizing
$btn-padding-top--small: rem(7px);
$btn-padding-bottom--small: $btn-padding-top--small;
$btn-padding-right--small: rem(14px);
$btn-padding-left--small: $btn-padding-right--small;
$btn-height--small: calc(
  $btn-line-height--small + $btn-padding-top--small + $btn-padding-bottom--small + ($btn-border-width--small * 2)
);
//icons sizing
$btn-icon-size--small: $btn-line-height--small;
$btn-icon-gap--small: rem(8px);
$btn-icon-padding-right--small: $btn-padding-top--small;
$btn-icon-padding-left--small: $btn-padding-top--small;
//outline sizing
$btn-ring-width--small: 1px;
$btn-ring-offset--small: 4px;

//  LARGE SIZE  //  LARGE SIZE  //  LARGE SIZE  //
//  LARGE SIZE  //  LARGE SIZE  //  LARGE SIZE  //
//borders sizing
$btn-border-width--large: 1px;
$btn-border-radius--large: $br--large;
//font sizing
$btn-font-size--large: rem(18px);
$btn-line-height--large: rem(22px);
$btn-letter-spacing--large: 0;
$btn-font-weight--large: 600;
$btn-text-transform--large: unset;
//general sizing
$btn-padding-top--large: rem(13px);
$btn-padding-bottom--large: $btn-padding-top--large;
$btn-padding-right--large: rem(18px);
$btn-padding-left--large: $btn-padding-right--large;
$form-padding-right--large: calc(
  $btn-padding-top--large +
    (($btn-line-height--large - $btn-font-size--large) / 2)
);
$form-padding-left--large: calc(
  $btn-padding-top--large +
    (($btn-line-height--large - $btn-font-size--large) / 2)
);
$btn-height--large: calc(
  $btn-line-height--small + $btn-padding-top--large + $btn-padding-bottom--large + ($btn-border-width--large * 2)
);
//outline sizing
$btn-ring-width--large: 1px;
$btn-ring-offset--large: 4px;
$btn-icon-padding-right--large: $btn-padding-top--large;
$btn-icon-padding-left--large: $btn-padding-top--large;
//icons sizing
$btn-icon-size--large: $btn-line-height--large;
$btn-icon-gap--large: rem(12px);

//  THEME NEUTRAL  //  THEME NEUTRAL  //  THEME NEUTRAL  //
//  THEME NEUTRAL  //  THEME NEUTRAL  //  THEME NEUTRAL  //
// theme neutral
$btn-color-bg: var(--color-neutral-20);
$btn-color-border: var(--color-neutral-20);
$btn-color-font: var(--color-neutral-90);
$btn-color-ring: var(--color-neutral-20);
// theme neutral __hover
$btn-color-bg__hover: var(--color-neutral-30);
$btn-color-border__hover: var(--color-neutral-30);
$btn-color-font__hover: var(--color-neutral-100);
// theme neutral __active
$btn-color-bg__active: var(--color-neutral-10);
$btn-color-border__active: var(--color-neutral-10);
$btn-color-font__active: var(--color-neutral-100);
// theme neutral __disabled
$btn-color-bg__disabled: var(--color-neutral-80);
$btn-color-border__disabled: var(--color-neutral-90);
$btn-color-font__disabled: var(--color-neutral-60);
// theme neutral --outline
$btn-color-bg--outline: transparent;
$btn-color-border--outline: var(--color-neutral-50);
$btn-color-font--outline: var(--color-neutral-40);
$btn-color-ring--outline: var(--color-neutral-20);
// theme neutral --outline __hover
$btn-color-bg--outline__hover: var(--color-neutral-30);
$btn-color-border--outline__hover: var(--color-neutral-30);
$btn-color-font--outline__hover: var(--color-neutral-100);
// theme neutral --outline __active
$btn-color-bg--outline__active: var(--color-neutral-30);
$btn-color-border--outline__active: var(--color-neutral-30);
$btn-color-font--outline__active: var(--color-neutral-100);
// theme neutral --outline __disabled
$btn-color-bg--outline__disabled: var(--color-neutral-80);
$btn-color-border--outline__disabled: var(--color-neutral-90);
$btn-color-font--outline__disabled: var(--color-neutral-60);

//  THEME PRIMARY  //  THEME PRIMARY  //  THEME PRIMARY  //
//  THEME PRIMARY  //  THEME PRIMARY  //  THEME PRIMARY  //
// theme --primary
$btn-color-bg--primary: var(--color-primary-40);
$btn-color-border--primary: var(--color-primary-40);
$btn-color-font--primary: var(--color-neutral-90);
$btn-color-ring--primary: var(--color-primary-20);
// theme --primary __hover
$btn-color-bg--primary__hover: var(--color-primary-30);
$btn-color-border--primary__hover: var(--color-primary-30);
$btn-color-font--primary__hover: var(--color-neutral-100);
// theme --primary __active
$btn-color-bg--primary__active: var(--color-primary-20);
$btn-color-border--primary__active: var(--color-primary-20);
$btn-color-font--primary__active: var(--color-neutral-100);
// theme --primary __disabled
$btn-color-bg--primary__disabled: var(--color-neutral-80);
$btn-color-border--primary__disabled: var(--color-neutral-90);
$btn-color-font--primary__disabled: var(--color-neutral-60);
// theme --primary --outline
$btn-color-bg--outline--primary: transparent;
$btn-color-border--outline--primary: var(--color-primary-40);
$btn-color-font--outline--primary: var(--color-primary-40);
$btn-color-ring--outline--primary: var(--color-neutral-20);
// theme --primary --outline __hover
$btn-color-bg--outline--primary__hover: var(--color-primary-30);
$btn-color-border--outline--primary__hover: var(--color-primary-30);
$btn-color-font--outline--primary__hover: var(--color-neutral-100);
// theme --primary --outline __active
$btn-color-bg--outline--primary__active: var(--color-primary-30);
$btn-color-border--outline--primary__active: var(--color-primary-30);
$btn-color-font--outline--primary__active: var(--color-neutral-100);
// theme --outline --primary __disabled
$btn-color-bg--outline--primary__disabled: var(--color-neutral-80);
$btn-color-border--outline--primary__disabled: var(--color-neutral-90);
$btn-color-font--outline--primary__disabled: var(--color-neutral-60);

//  THEME ERROR  //  THEME ERROR  //  THEME ERROR  //
//  THEME ERROR  //  THEME ERROR  //  THEME ERROR  //
// theme --error
$btn-color-bg--error: var(--color-error-40);
$btn-color-border--error: var(--color-error-40);
$btn-color-font--error: var(--color-neutral-90);
$btn-color-ring--error: var(--color-error-20);
// theme --error __hover
$btn-color-bg--error__hover: var(--color-error-30);
$btn-color-border--error__hover: var(--color-error-30);
$btn-color-font--error__hover: var(--color-neutral-100);
// theme --error __active
$btn-color-bg--error__active: var(--color-error-20);
$btn-color-border--error__active: var(--color-error-20);
$btn-color-font--error__active: var(--color-neutral-100);
// theme --error __disabled
$btn-color-bg--error__disabled: var(--color-error-60);
$btn-color-border--error__disabled: var(--color-error-60);
$btn-color-font--error__disabled: var(--color-neutral-100);
// theme --error --outline
$btn-color-bg--outline--error: transparent;
$btn-color-border--outline--error: var(--color-error-40);
$btn-color-font--outline--error: var(--color-error-40);
$btn-color-ring--outline--error: var(--color-neutral-20);
// theme --error --outline __hover
$btn-color-bg--outline--error__hover: var(--color-error-30);
$btn-color-border--outline--error__hover: var(--color-error-30);
$btn-color-font--outline--error__hover: var(--color-neutral-100);
// theme --error --outline __active
$btn-color-bg--outline--error__active: var(--color-error-30);
$btn-color-border--outline--error__active: var(--color-error-30);
$btn-color-font--outline--error__active: var(--color-neutral-100);
// theme --error --outline __disabled
$btn-color-bg--outline--error__disabled: var(--color-error-60);
$btn-color-border--outline--error__disabled: var(--color-error-60);
$btn-color-font--outline--error__disabled: var(--color-neutral-100);

//  THEME NO BG  //  THEME NO BG  //  THEME NO BG  //
//  THEME NO BG  //  THEME NO BG  //  THEME NO BG  //
// theme --no-bg
$btn-color-bg--no-bg: transparent;
$btn-color-border--no-bg: transparent;
$btn-color-font--no-bg: var(--color-neutral-40);
$btn-color-ring--no-bg: var(--color-neutral-20);
// theme --no-bg __hover
$btn-color-bg--no-bg__hover: transparent;
$btn-color-border--no-bg__hover: transparent;
$btn-color-font--no-bg__hover: var(--color-primary-40);
// theme --no-bg __active
$btn-color-bg--no-bg__active: transparent;
$btn-color-border--no-bg__active: transparent;
$btn-color-font--no-bg__active: var(--color-primary-30);
// theme --no-bg __disabled
$btn-color-bg--no-bg__disabled: transparent;
$btn-color-border--no-bg__disabled: transparent;
$btn-color-font--no-bg__disabled: var(--color-neutral-60);
// theme --no-bg --outline
$btn-color-bg--outline--no-bg: transparent;
$btn-color-border--outline--no-bg: transparent;
$btn-color-font--outline--no-bg: var(--color-neutral-40);
$btn-color-ring--outline--no-bg: var(--color-neutral-20);
// theme --no-bg --outline __hover
$btn-color-bg--outline--no-bg__hover: transparent;
$btn-color-border--outline--no-bg__hover: transparent;
$btn-color-font--outline--no-bg__hover: var(--color-primary-40);
// theme --no-bg --outline __active
$btn-color-bg--outline--no-bg__active: transparent;
$btn-color-border--outline--no-bg__active: transparent;
$btn-color-font--outline--no-bg__active: var(--color-primary-30);
// theme --no-bg --outline __disabled
$btn-color-bg--outline--no-bg__disabled: transparent;
$btn-color-border--outline--no-bg__disabled: transparent;
$btn-color-font--outline--no-bg__disabled: var(--color-neutral-60);